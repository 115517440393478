<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- fromHireDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromHireDate"
            label-text="fromHireDate"
          />
        </b-col>

        <!-- toHireDate -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toHireDate"
            label-text="toHireDate"
          />
        </b-col>

        <!-- fromResidenceDate  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromResidenceDate"
            label-text="fromResidenceDate"
          />
        </b-col>

        <!-- toResidenceDate -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toResidenceDate"
            label-text="toResidenceDate"
          />
        </b-col>

        <!-- employeeId  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employeeId"
              :options="employees"
              label="displayName"
            />
          </b-form-group>
        </b-col>

        <!-- includeSuspendedEmployees -->
        <b-col md="4">
          <label style="font-size: 14px; margin-bottom: 7px">
            {{ $t("includeSuspendedEmployees") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="helper.includeSuspended"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
  mixins: [reportMixin],
  props: {
      type: {
        type: Object,
        default: null,
      },
    },
  data() {
    return {
      employees: [],
      selectedItem: {
        fromHireDate: this.today,
        toHireDate: this.today,
        fromResidenceDate: this.today,
        toResidenceDate: this.today,
        employeeId: null,
        isSuspended: null
      },
      helper: {
        includeSuspended: false,
        startDate: this.today,
        endDate: this.today
      }
    };
  },
  beforeMount() {
    var appLaunchPeriodForCurrentTenant = this.fiscalYears.find(fiscal => fiscal.year === null)
    if (appLaunchPeriodForCurrentTenant) {
      this.helper.startDate = appLaunchPeriodForCurrentTenant.startDate
      this.helper.endDate = appLaunchPeriodForCurrentTenant.endDate
    } else {
      this.helper.startDate = this.currentYear.startDate;
      this.helper.endDate = this.currentYear.endDate;
    }
  },
  mounted() {
    this.selectedItem.fromHireDate = this.getDate(this.helper.startDate)
    this.selectedItem.toHireDate = this.getDate(this.helper.endDate)
    this.selectedItem.fromResidenceDate = this.getDate(this.helper.startDate)
    this.selectedItem.toResidenceDate = this.getDate(this.helper.endDate)
    this.getData();
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromHireDate === null) {
        this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toHireDate === null) {
        this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data;
        data.forEach(employee => {
          employee.displayName = `${employee.code} - ${this.isRight ? employee.arabicName : employee.englishName}`;
        });
      })
    },
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },
    preparePrametersForPrint() {
      const selectedEmployee = this.filterDataBaseOnId(this.employees, this.selectedItem.employeeId);
      const allEmployees = this.filterDataBaseOnId(reportParameters, 'allEmployees');

      this.selectedItem.employeeName = this.selectedItem.employeeId
                                     ? selectedEmployee.displayName
                                     : this.isRight ? allEmployees.arabicName : allEmployees.englishName;

      this.selectedItem.includedSuspendedEmployees = this.helper.includeSuspended
                                                   ? this.$t('yes')
                                                   : this.$t('no')
    },
    handlePrintedItem() {
      this.selectedItem.isSuspended = this.helper.includeSuspended ? null : false;
    },
    print() {
      this.checkDate();
      this.preparePrametersForPrint();
      this.handlePrintedItem();
      this.printReport(this.isRight ? 'EmployeesResidence-ar' : 'EmployeesResidence-en', this.selectedItem);
    },
  },
};
</script>
