<template>
  <b-card>
    <b-card class="border-primary bordered-primary card m-auto w-75">
      <span
        v-if="
          !checkedItems.isComplianceComplete &&
            !checkedItems.zatcaBinarySecurityToken
        "
      >
        <b-row
          class="pt-1"
          v-if="
            !checkedItems.isComplianceComplete &&
              !checkedItems.zatcaBinarySecurityToken
          "
        >
          <b-col md="3"></b-col>
          <b-col md="6" class="text-center">
            <label class="d-block" style="font-size: 16px; margin-bottom: 7px">
              {{ $t('otp') }}</label
            >
            <otp-input
              style="direction: ltr"
              ref="otpInput"
              input-classes="otp-input"
              class="p-1 d-inline-flex"
              separator=" -"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
            />
          </b-col>
          <b-col md="3"></b-col>
        </b-row>
      </span>
      <b-row
        v-if="
          checkedItems.isComplianceComplete === true &&
            checkedItems.zatcaBinarySecurityToken !== null
        "
      >
        <b-col md="12">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <h3>{{ $t('configurationDoneBefore') }}</h3>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        v-if="
          checkedItems.isComplianceComplete === false &&
            checkedItems.zatcaBinarySecurityToken !== null
        "
      >
        <b-col md="12">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <h3>{{ $t('tryReConfigration') }}</h3>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
    <b-row
      v-if="
        (!checkedItems.isComplianceComplete &&
          !checkedItems.zatcaBinarySecurityToken) ||
          (!checkedItems.isComplianceComplete &&
            checkedItems.zatcaBinarySecurityToken)
      "
    >
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          class="mx-1"
          @click="
            () => {
              this.$refs['confirmation-modal'].show();
            }
          "
          variant="primary"
          data-action-type="save"
        >
          {{ $t('register') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- </g-form> -->
    <b-modal
      no-close-on-backdrop
      ref="confirmation-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('confirmPassword')"
    >
      <b-row>
        <b-col cols="6">
          <g-field
            :value.sync="selectedItem.password"
            type="password"
            rules="required"
            label-text="adminPassword"
          />
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              v-if="
                !checkedItems.zatcaBinarySecurityToken &&
                  !checkedItems.isComplianceComplete
              "
              :disabled="!selectedItem.password && !selectedItem.otp"
              class="mx-1"
              @click="save"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('register') }}
            </b-button>
            <b-button
              v-if="
                checkedItems.zatcaBinarySecurityToken &&
                  !checkedItems.isComplianceComplete
              "
              :disabled="!selectedItem.password"
              class="mx-1"
              @click="reSaveComplete"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('register') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input';

export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      totalRows: 0,
      sortBy: 'id',
      isSortDirDesc: true,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      sortDirection: 'asc',
      filter: null,
      selectedItem: {
        otp: '',
        password: '',
      },
      checkedItems: {},
    };
  },
  computed: {
    tableColumnsDevices() {
      return [
        { key: 'deviceCode', label: this.$t('deviceCode') },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    addDevice() {
      this.selectedItem.devices.push({
        deviceCode: this.selectedItem.deviceCode,
      });
      this.selectedItem.deviceCode = '';
    },
    removeDevice(_obj) {
      const indx = this.selectedItem.devices.indexOf(_obj);
      this.selectedItem.devices.splice(indx, 1);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    handleOnComplete(value) {
      this.selectedItem.otp = value;
    },
    getData() {
      this.get({ url: 'Schools/@current' }).then((data) => {
        this.checkedItems = data;
        // this.checkedItems.zatcaBinarySecurityToken = '344rno4ntn'
        // this.checkedItems.isComplianceComplete = false
      });
    },
    reSaveComplete() {
      const dataObj = {
        password: this.selectedItem.password,
      };
      this.create({
        url: 'Schools/zatca/complete',
        data: dataObj,
      }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        window.location.reload();
        this.getData();
      });
    },
    save() {
      this.create({
        url: 'Schools/zatca/init',
        data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        window.location.reload();
        this.getData();
      });
    },
  },
};
</script>

<style lang="scss">
/* #cke_11, #cke_19, #cke_21, #cke_25, #cke_30, #cke_32 {
     display: none !important;
    } */
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
