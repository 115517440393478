<template>
    <b-card class="w-50 m-auto">
      <g-form @submit="post">
        <b-row>
            <!-- from Date -->
               <b-col md="6">
                <g-picker
                  :value.sync="selectedItem.fromDate"
                  label-text="date"
                  name="date"
                />
              </b-col>
          <!-- to Date -->
            <b-col md="6">
                <g-picker
                  :value.sync="selectedItem.toDate"
                  label-text="date"
                  name="date"
                />
              </b-col>
        </b-row>
        <b-row>
              <b-col md="6">
            <!-- from Code  -->
            <g-field
              :value.sync="selectedItem.fromCode"
              label-text="fromCode"
              name="code"
              rules="integer"
            />
          </b-col>
           <!-- to Code -->
          <b-col md="6">
             <g-field
              :value.sync="selectedItem.toCode"
              label-text="toCode"
              name="code"
              rules="integer"
            />
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col md="4">
            <b-form-group :label="$t('includeSales')">
                    <b-form-checkbox
                      v-model="selectedItem.includeSales"
                      class="custom-control-primary"
                    >
            </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="$t('DebitNote')">
                    <b-form-checkbox
                      v-model="selectedItem.includeReturnSales"
                      class="custom-control-primary"
                    >
            </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
         <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          </b-row>
          <b-row>
          <b-col md="3"></b-col>
          <b-col md="6">
            <b-button class="mx-1 btn-block" type="submit" variant="primary" data-action-type="save" v-permission="$route.meta.permission">
              {{ $t('reported') }}
            </b-button>
          </b-col>
          <b-col md="3"></b-col>
        </b-row>
      </g-form>
      </b-card>
  </template>
  <script>
  export default {
    data() {
      return {
        selectedItem: {
          fromDate: '',
          toDate: '',
          fromCode: 0,
          toCode: 0,
          includeSales: false,
          includeReturnSales: false
        },
      };
    },
    mounted() {
       this.selectedItem.fromDate = this.today;
      this.selectedItem.toDate = this.today;
    },
    methods: {
      post() {
        if (this.selectedItem.fromCode === '' || this.selectedItem.fromCode === 0) this.selectedItem.fromCode = null;
        if (this.selectedItem.toCode === '' || this.selectedItem.toCode === 0) this.selectedItem.toCode = null;
          this.create({
            url: 'Invoices/zatca/send',
            data: this.selectedItem,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('savedSuccessfully') });
            }).catch(({ data }) => {
            this.doneAlert({ title: this.$t(data.detail), type: 'error' });
          });
        }
    },
  };
  </script>
