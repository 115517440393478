<template>
  <b-card>

    <b-row>
      <b-button
        class="btn-filter"
        variant="adn"
        @click=" () => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="transactionNumber"
          v-silent-search
          :value.sync="filter.transactionNumber"
          label-text="transactionNumber"
          name="transactionNumber"
          @keydown.enter="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('fromDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateFrom"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateFrom"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('toDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.transactionDateTo"
          locale="ar"
          :date-format-options=" {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="transactionDateTo"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <!-- <g-field
          :value.sync="filter.studentId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="student"
          field="select"
          :options="students"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        /> -->
        <student-autocomplete
          ref="autoComlete"
          name="student"
          :value.sync="filter.studentId"
          url="students/getStudentsTaxLookup"
          @change:action="
            (val) => {
              this.students.push(val), refreshItems();
            }
          "
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.stageId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="stage"
          field="select"
          :options="stages"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.rowId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="row"
          field="select"
          :options="schoolRows"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.classroomId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="class"
          field="select"
          :options="classrooms"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.discountId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="discount"
          field="select"
          :options="discounts"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- group type selection -->
      <b-col
        class="gb-package mt-1"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("accountsPostSituation") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', {btnActive: selectedGroupName === 'all'}]"
            variant="flat-warning"
            @click="() => {
              selectedGroupName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: selectedGroupName === 'posted'}]"
            variant="flat-warning"
            @click="() => {
              selectedGroupName = 'posted'
              refreshItems()
            }"
          >
            {{ $t("posted") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', {btnActive: selectedGroupName === 'unPosted'}]"
            variant="flat-warning"
            @click="() => {
              selectedGroupName = 'unPosted'
              refreshItems()
            }"
          >
            {{ $t("unPosted") }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- buttons operations -->
    <b-row
      align-h="between"
      class="mb-1 mt-1"
    >
      <b-col
        md="12"
        class="list-buttons d-flex align-items-center justify-content-end mb-1"
      >
        <b-button
          v-permission="'addStudentDiscounts'"
          variant="primary"
          data-action-type="new"
          @click="
            (v) => {
              $router.push({ name: 'studentDiscounts-new' });
            }
          "
        >
          {{ $t("new") }}
        </b-button>

        <vue-excel-xlsx
          :data="itemsArray"
          :columns="tableColumns"
          :filename="this.$route.name"
          :sheetname="'xlsxSheet'"
          class="btn btn-relief-success ml-1 p-0 btn-sm"
        >
          <img
            src="@/assets/images/icons/xls.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </vue-excel-xlsx>
        <b-button
          variant="relief-danger"
          class="ml-1 p-0 btn-sm"
          @click="printDocument"
        >
          <img
            src="@/assets/images/icons/pdf.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </b-button>
      </b-col>
    </b-row>

    <!-- create table contain all student discounts defined and opertaions allowed -->
    <g-table
      ref="studentDiscounts-table"
      foot-clone
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >

      <template
        v-if="!hideActions"
        #actions="{ item }"
      >
        <div class="text-nowrap">

          <!-- print button -->
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>

          <!-- edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editStudentDiscounts'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>

          <!-- delete button -->
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteStudentDiscounts'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

  <script src="@/pages/Shared/html2canvas.js"></script>
  <script>
  import GTable from '@/pages/Shared/Table.vue';
  import { rows } from '@/libs/acl/Lookups';
  import reportMixin from '@/mixin/reportMixin';
  import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

  export default {
    components: {
      GTable,
      StudentAutocomplete
    },
    mixins: [reportMixin],
    data() {
      return {
        items: [],
        isTableBusy: false,
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        rows: rows,
        modal: false,
        totalDiscounts: 0,
        filter: {
          transactionNumber: null,
          transactionDateFrom: null,
          transactionDateTo: null,
          studentId: null,
          stageId: null,
          rowId: null,
          classroomId: null,
          discountId: null,
          isPosted: null
        },

        students: [],
        stages: [],
        schoolRows: [],
        classrooms: [],
        discounts: [],
        posts: [],
        selectedGroupName: 'all',
        showFilter: false,
        fiscalYearStart: null,
        fiscalYearEnd: null,
        itemsArray: [],
        hideActions: false
      };
    },
    computed: {
      tableColumns() {
        return [
          { key: 'code', label: this.$t('transactionNumber'), sortable: true },
          { key: 'transactionDate', label: this.$t('date'), sortable: true },
          { key: 'studentName', label: this.$t('studentName'), sortable: true },
          { key: 'discountType', label: this.$t('discountType'), sortable: true },
          {
            key: 'studentDiscountValue',
            label: this.$t('value'),
            sortable: true,
            footer: () => this.fraction(this.totalDiscounts)
          },
          {
            key: 'postStatuts',
            label: this.$t('isPosted'),
            formatter: (key, value, tanx) => {
              if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1 p-50">${this.$t('posted')}</span>`
              return `<span class="badge bg-danger bg-darken-2 p-50">${this.$t('unPosted')}</span>`;
            }
          },
          { key: 'notes', label: this.$t('notes'), sortable: false },
          { key: 'actions' },
        ];
      },
    },
    watch: {
    'filter.transactionDateFrom'(newVal) {
      if (newVal > this.filter.transactionDateTo) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.transactionDateTo'(newVal) {
      if (this.filter.transactionDateFrom > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
    beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.filter.transactionDateFrom = this.getDate(this.fiscalYearStart)
    this.filter.transactionDateTo = this.getDate(this.fiscalYearEnd)
    this.loadData();
  },

    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      remove(item) {
        this.confirmAction(
          {
            text: this.$t('areYouSureYouWantToDelete'),
          },
          () => {
            this.delete({ url: 'StudentDiscounts', id: item.id }).then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.refreshItems();
            });
          }
        );
      },

      edit(item) {
        this.$router.push({
          name: 'studentDiscounts-edit',
          params: { id: item.id },
        });
      },

      print(item) {
        const reportName = this.isRight ? 'StudentDiscount-ar' : 'StudentDiscount-en';
        const printedItem = {
          id: item.id,
        }
        this.printReport(reportName, printedItem);
      },

      printDocument() {
        this.hideActions = true;
        setTimeout(() => {
          this.pdfExport(this.$route.name);
          this.hideActions = false;
        }, 500);
      },

      pdfExport(name) {
        html2canvas(document.getElementById("mainTable")).then(function (canvas) {
          var imgData = canvas.toDataURL("image/png");
          var imgWidth = 210;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jsPDF("p", "mm");
          var position = 0;

          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save(`${name}.pdf`);
        });
      },

      itemsProvider(ctx, callback) {
        const {
          currentPage, perPage, sortBy, sortDesc
        } = ctx;

        this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
        var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

        this.setFilertPost();
        params += this.getFilterObj(this.filter);
        this.isTableBusy = true;
        this.get({ url: `StudentDiscounts${params}` })
          .then((data ) => {
            this.totalDiscounts = 0;
            data.pageData.forEach(element => {
              element.transactionDate = this.getDate(element.transactionDate);
              this.totalDiscounts += element.studentDiscountValue
            });
            this.isTableBusy = false;
            this.totalRows = data.totalRecords;
            callback(data.pageData);

            // then set items for excel and pdf
            this.itemsArray = data.pageData;
            this.itemsArray.forEach(element => {
              element.postStatuts = element.isPosted ? this.$t('posted') : this.$t('unPosted');
            });
          })
          .catch(() => {
            this.isTableBusy = false;
            callback([]);
          });
        return null;
      },

      refreshItems() {
        this.$refs['studentDiscounts-table'].refreshTable();
      },

      ResetSelection() {
        if (!this.showFilter) {
          this.filter.transactionDateFrom = this.fiscalYearStart;
          this.filter.transactionDateTo = this.fiscalYearEnd;
          this.filter.transactionNumber = null;
          this.filter.studentId = null;
          this.filter.stageId = null;
          this.filter.rowId = null;
          this.filter.classroomId = null;
          this.filter.discountId = null;
          this.selectedGroupName = 'all';
          this.refreshItems();
        }
      },

      loadData() {
        this.get({ url: 'Stages' }).then((data) => {
          this.stages = data;
        });
        this.get({ url: 'Rows' }).then((data) => {
          this.schoolRows = data;
        });
        this.get({ url: 'Classrooms' }).then((data) => {
          this.classrooms = data;
        });
        this.get({ url: 'students/getStudentsLookup' }).then((data) => {
          this.students = data;
        });
        this.get({ url: 'discounts' }).then((data) => {
          this.discounts = data;
        })
      },

      setFilertPost() {
        switch (this.selectedGroupName) {
          case 'posted':
            this.filter.isPosted = true;
            break;

          case 'unPosted':
            this.filter.isPosted = false;
            break;

          default:
            this.filter.isPosted = null;
            break;
        }
      }
    },
  };
  </script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border: 2px solid #ad8884;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}
b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
