var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card',{staticClass:"border-primary bordered-primary card m-auto w-75"},[(
        !_vm.checkedItems.isComplianceComplete &&
          !_vm.checkedItems.zatcaBinarySecurityToken
      )?_c('span',[(
          !_vm.checkedItems.isComplianceComplete &&
            !_vm.checkedItems.zatcaBinarySecurityToken
        )?_c('b-row',{staticClass:"pt-1"},[_c('b-col',{attrs:{"md":"3"}}),_c('b-col',{staticClass:"text-center",attrs:{"md":"6"}},[_c('label',{staticClass:"d-block",staticStyle:{"font-size":"16px","margin-bottom":"7px"}},[_vm._v(" "+_vm._s(_vm.$t('otp')))]),_c('otp-input',{ref:"otpInput",staticClass:"p-1 d-inline-flex",staticStyle:{"direction":"ltr"},attrs:{"input-classes":"otp-input","separator":" -","num-inputs":6,"should-auto-focus":true,"is-input-num":true},on:{"on-complete":_vm.handleOnComplete}})],1),_c('b-col',{attrs:{"md":"3"}})],1):_vm._e()],1):_vm._e(),(
        _vm.checkedItems.isComplianceComplete === true &&
          _vm.checkedItems.zatcaBinarySecurityToken !== null
      )?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-body text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('configurationDoneBefore')))])])])],1)],1):_vm._e(),(
        _vm.checkedItems.isComplianceComplete === false &&
          _vm.checkedItems.zatcaBinarySecurityToken !== null
      )?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-body text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('tryReConfigration')))])])])],1)],1):_vm._e()],1),(
      (!_vm.checkedItems.isComplianceComplete &&
        !_vm.checkedItems.zatcaBinarySecurityToken) ||
        (!_vm.checkedItems.isComplianceComplete &&
          _vm.checkedItems.zatcaBinarySecurityToken)
    )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"primary","data-action-type":"save"},on:{"click":function () {
            this$1.$refs['confirmation-modal'].show();
          }}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")])],1)],1):_vm._e(),_c('b-modal',{ref:"confirmation-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"lg","hide-footer":"","title":_vm.$t('confirmPassword')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('g-field',{attrs:{"value":_vm.selectedItem.password,"type":"password","rules":"required","label-text":"adminPassword"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "password", $event)}}})],1)],1),_c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[(
              !_vm.checkedItems.zatcaBinarySecurityToken &&
                !_vm.checkedItems.isComplianceComplete
            )?_c('b-button',{staticClass:"mx-1",attrs:{"disabled":!_vm.selectedItem.password && !_vm.selectedItem.otp,"variant":"primary","data-action-type":"save"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")]):_vm._e(),(
              _vm.checkedItems.zatcaBinarySecurityToken &&
                !_vm.checkedItems.isComplianceComplete
            )?_c('b-button',{staticClass:"mx-1",attrs:{"disabled":!_vm.selectedItem.password,"variant":"primary","data-action-type":"save"},on:{"click":_vm.reSaveComplete}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }