<template>
  <b-card>
    <b-tabs
      content-class="mt-2"
      pills
      justified
    >
      <!-- transactions Copy  -->
      <b-tab :title="$t('transactionsCopy')">
        <g-form @submit="getDataFromSource(selectedItem)">
          <b-row class="d-flex justify-content-center">
            <!-- transactionType -->
            <b-col md="3">
              <b-form-group>
                <g-field
                  :value.sync="transactionType"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="transactionType"
                  field="select"
                  name="transactionType"
                  :options="transactions"
                  label="arabicName"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- itemTransactionsType (invoice/purchase) -->
            <!-- <b-col
              v-if="transactionType === 'itemTransactions'"
              md="3"
            >
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.type"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="transaction"
                  field="select"
                  name="itemTransactionsTypes"
                  :rules="transactionType === 'itemTransactions' ? 'required' : ''"
                  :options="itemTransactionsTypes"
                  label="arabicName"
                  @input="() => {}"
                />
              </b-form-group>
            </b-col> -->

            <!-- code -->
            <b-col md="2">
              <g-field
                id="code"
                label-text="code"
                :value.sync="selectedItem.code"
                rules="required"
                name="code"
              />
            </b-col>

            <!-- search button -->
            <b-col md="1">
              <b-button
                style="margin-top: 2rem !important"
                size="md"
                variant="relief-primary"
                type="submit"
                class="center"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="14"
                  width="40px"
                />
              </b-button>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-center">
            <!-- itemTransactionsType (invoice/purchase) details -->
            <!-- <b-col
              v-if="transactionDetails.id && transactionType !== 'voucher'"
              cols="3"
            >
              <b-card class="border-primary">
                <b-card-header>
                  <span class="badge badge-primary">{{
                    transactionDetails.code
                  }}</span>
                </b-card-header>
                <b-card-body class="d-flex justify-content-between">
                  <div class="design-group">
                    <h6 class="section-label">
                      {{ this.$t("transactionType") }}
                    </h6>
                    <span class="badge mr-1 badge-light-warning">
                      {{
                        transactionDetails.transactionType == "sales"
                          ? this.$t("salesPoint")
                          : this.$t("purch")
                      }}
                    </span>
                  </div>
                  <div class="design-group">
                    <h6 class="section-label">
                      {{ this.$t("transactionDate") }}
                    </h6>
                    <span class="badge mr-1 badge-light-info">
                      {{ getDate(transactionDetails.transactionDate) }}
                    </span>
                  </div>
                  <div class="design-group">
                    <h6 class="section-label">
                      {{ this.$t("paymentType") }}
                    </h6>
                    <span class="badge mr-1 badge-light-success">
                      {{
                        transactionDetails.paymentType == "cash"
                          ? this.$t("cash")
                          : this.$t("creditt")
                      }}
                    </span>
                  </div>
                </b-card-body>
                <b-card-footer>
                  <b-button
                    class="btn-block btn-sm"
                    type="button"
                    variant="primary"
                    @click="copyItem()"
                  >
                    {{ $t("copy") }}
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </b-card-footer>
              </b-card>
            </b-col> -->

            <!-- voucher details -->
            <b-col
              v-if="transactionDetails.id && transactionType === 'voucher'"
              md="6"
            >
              <!-- voucher code -->
              <b-card class="border-primary">
                <b-card-header>
                  <span
                    class="badge p-1 mb-1 font-medium-3 code-style"
                  > {{ transactionDetails.code }} </span>
                </b-card-header>

                <b-card-body class="d-flex justify-content-between">
                  <!-- voucher type -->
                  <div class="design-group">
                    <h5 class="mb-50">
                      {{ this.$t("sourceTransactionType") }}
                    </h5>
                    <span class="badge mr-1 p-50 badge-light-warning font-small-3">
                      {{ voucherType }}
                    </span>
                  </div>

                  <!-- voucher date -->
                  <div class="design-group">
                    <h5 class="mb-50">
                      {{ this.$t("date") }}
                    </h5>
                    <span class="badge mr-1 p-50 badge-light-danger font-small-3">
                      {{ getDate(transactionDetails.voucherDate) }}
                    </span>
                  </div>

                  <!-- voucher description -->
                  <div class="design-group">
                    <h5 class="mb-50">
                      {{ this.$t("notes") }}
                    </h5>
                    <span class="badge mr-1 p-50 badge-light-success font-small-3">
                      {{ transactionDetails.description || $t('noDiscriptionFound') }}
                    </span>
                  </div>
                </b-card-body>

                <!-- copy button -->
                <div class="ml-2 mr-2 mt-1">
                  <b-button
                    class="btn-block btn-sm p-75 font-small-4"
                    type="button"
                    variant="relief-primary"
                    @click="copyItem()"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="16"
                      class="mr-50"
                    />
                    {{ $t("copy") }}
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </g-form>
      </b-tab>
    <!-- <b-tab :title="$t('transferItemsFromStoreToStore')">
      <g-form @submit="saveTransfer">
        <b-row>
          <b-col md="4">
            <g-field
              :options="stores"
              label-text="storeFrom"
              field="select"
              rules="required"
              name="StoreId"
              :value.sync="storeTransferItems.sourceStoreId"
              :dir="isRight ? 'rtl' : 'ltr'"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-col>
          <b-col md="4">
            <g-field
              :options="stores"
              label-text="storeTo"
              field="select"
              rules="required"
              name="StoreId"
              :value.sync="storeTransferItems.destinationStoreId"
              :dir="isRight ? 'rtl' : 'ltr'"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              class="mx-1"
              type="submit"
              variant="primary"
              data-action-type="transferItems"
            >
              {{ $t("save") }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-tab> -->
    </b-tabs>
  </b-card>
</template>

<script>
import {
  transactions,
  itemTransactionsTypes,
} from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      transactions,
      itemTransactionsTypes,
      transactionType: null,
      selectedItem: {
        type: null,
        code: null
      },
      filter: {
        fromDate: '',
        toDate: '',
      },
      storeTransferItems: {},
      stores: [],
      transactionDetails: {}
    };
  },
  computed: {
    voucherType() {
      const name = this.isRight ? this.transactionDetails.sourceTransactionType : this.transactionDetails.sourceTransactionType;
      if (!this.transactionDetails.sourceTransactionType && !this.transactionDetails.isUnderClosure) return this.$t('manualVoucher');
      if (this.transactionDetails.isUnderClosure) return this.$t('closedVoucher');
      return this.$t(name);
    }
  },
  beforeMount() {
    this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
    this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
  },
  mounted() {
    this.filter.fromDate = this.getDate(this.fiscalYearStart)
    this.filter.toDate = this.getDate(this.fiscalYearEnd);
    this.transactionType = 'voucher'
    // this.getData();
  },
  methods: {
    copyItem() {
      // if (this.transactionType === 'itemTransactions' && this.selectedItem.type === 'Sales') {
      //   this.$router.push({
      //     name: 'pos-invoice',
      //     query: {
      //       sourceTransactionId: this.transactionDetails.id,
      //       action: 'copy',
      //       sourceTransactionType: this.transactionDetails.transactionType,
      //     },
      //   });
      // }
      //  if (this.transactionType === 'itemTransactions' && this.selectedItem.type === 'Purch') {
      //   this.$router.push({
      //     name: 'purchInvoice',
      //     query: {
      //       sourceTransactionId: this.transactionDetails.id,
      //       action: 'copy',
      //       sourceTransactionType: this.transactionDetails.transactionType,
      //     },
      //   });
      // }
       if (this.transactionType === 'voucher') {
        if (!this.validatedBeforeCopyVoucher()) return;
        this.$router.push({
          name: this.transactionDetails.isUnderClosure ? 'closedVoucher-new' : 'vouchers-new',
          query: {
            sourceTransactionId: this.transactionDetails.id,
            action: 'copy'
          },
        });
      }
    },
    validatedBeforeCopyVoucher() {
      if (this.transactionDetails.isUnderClosure) {
        this.doneAlert({ type: 'warning', text: this.$t('cantCopyEndYearClosingVoucher'), timer: 5000 });
        return false;
      }
      return true;
    },
    getDataFromSource(data) {
      const url = this.transactionType === 'itemTransactions'
                ? `itemTransactions/categories/${data.type}/Summary?transactionType=${data.type}&category=${data.type}&Code=${data.code}`
                : `vouchers?&FromDate=${this.filter.fromDate}&ToDate=${this.filter.toDate}&Code=${data.code}`;

      this.get({ url }).then((res) => {
        if (res.data.length > 0) {
          this.transactionDetails = res.data[0];
        } else {
          this.doneAlert({ type: 'warning', text: this.$t('noTransactionsFound') });
        }
      })
    },
    getData() {
      this.get({ url: 'Stores' }).then((data) => {
        this.stores = data;
      });
    },
    beforeSaveValidations() {
      if (this.storeTransferItems.destinationStoreId === this.storeTransferItems.sourceStoreId) {
        this.doneAlert({ type: 'error', text: this.$t('notAllowedToTransferToSameStore') });
        return false;
      }
      return true;
    },
    saveTransfer() {
      if (!this.beforeSaveValidations()) return;
      this.create({ url: `ItemTransactions/Transfer/${this.storeTransferItems.sourceStoreId}/${this.storeTransferItems.destinationStoreId}` }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
      })
    }
  }
}
</script>

<style>
.code-style {
  background-color: #7367f0;
  box-shadow: 12px 12px 5px -3px #e0ddff;
}
</style>
