import Vue from 'vue';
import Router from '@/router';
import VueI18n from '@/libs/i18n/index';
import whatsAppLogo from '@/assets/images/icons/whatsapp.png';

// axios
import axios from 'axios';

import {
  baseURL, TOKEN_KEY, PROFILE_KEY, LANG, BRANCH_ID, CURRENT_YEAR,
} from './acl/config';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosIns.interceptors.request.use((request) => {
  if (request.method === 'post' || request.method === 'put') {
    if (request.data) {
      if (Object.prototype.hasOwnProperty.call(request.data, 'englishName')) {
        request.data.englishName = request.data.englishName || request.data.arabicName;
      }
    }
  }
  const token = window.localStorage.getItem(TOKEN_KEY);
  const lang = window.localStorage.getItem(LANG);
  const branchId = window.localStorage.getItem(BRANCH_ID);
  const fiscalYear = window.localStorage.getItem(CURRENT_YEAR);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  request.headers.Language = lang || 'en';
  request.headers.Branch = branchId;
  if (JSON.parse(fiscalYear)) {
    request.headers.FiscalYear = JSON.parse(fiscalYear).year;
  } else {
    request.headers.FiscalYear = new Date().getFullYear();
  }
  if (request.method === 'get' && (request.url === 'students/data-migration' || request.url === 'employees/data-migration')) {
    request.responseType = 'arraybuffer';
    request.headers.Accept = 'application/octet-stream'
  }
  if (request.method === 'post' && (request.url === 'employees/data-migration' || request.url === 'employees/data-migration')) {
    request.headers.ContentType = 'multipart/form-data'
  }

  return request;
});

axiosIns.interceptors.response.use(undefined, (error) => {
  const isNoSubscription = error.response.data.errors ? error.response.data.errors[0].code === 'User.NoActiveSubscriptionForTenantErrorMessage' : false;
  const isSubscriptionExpired = error.response.data.errors ? error.response.data.errors[0].code === 'User.ActiveSubscriptionForTenantExpired' : false;

  // const token = window.localStorage.getItem(TOKEN_KEY)
  if (error.response.status === 401) {
    // const lang = window.localStorage.getItem(LANG);
    window.localStorage.setItem(TOKEN_KEY, '');
    window.localStorage.removeItem(PROFILE_KEY);
    Vue.swal({
      icon: 'error',
      title: VueI18n.t('error'),
      text: error.response.data.title === 'Unauthorized' ? 'خطأ في اسم المستخدم او كلمة المرور' : error.response.data.detail,
      timer: 4000,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
    Router.push('/login');
  } else if (isNoSubscription || isSubscriptionExpired) {
    confirmActionTechSupport(
      {
        text: '',
        title: error.response.data.errors[0].message
      },
      () => {
        window.open(
          'http://wa.me/966564460371',
          '_blank'
        );
      }
    );
  } else if ([400, 409].includes(error.response.status)) {
    Vue.swal({
      icon: 'error',
      title: VueI18n.t('error'),
      text: !error.response.data.errors ? error.response.data.detail : error.response.data.errors[0].message,
      // text: error.response.data.detail,
      timer: 60000,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }
  return Promise.reject(error);
});

function confirmActionTechSupport(data, callbackFn) {
  const {
    title,
    text,
    type,
    confirmText,
    cancelText
  } = data;
  Vue.swal(
    {
    title: title,
    text: text,
    icon: type || 'warning',
    showCancelButton: false,
    confirmButtonText: confirmText || `<span class="align-middle">${VueI18n.t('contactWithUs')}</span><img src="${whatsAppLogo}" width="20" class="ml-50">`,
    cancelButtonText: cancelText || VueI18n.t('Cancel'),
    customClass: {
      confirmButton: 'btn btn-whatsApp text-white',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
  }
  )
  .then(
    (result) => {
    if (result.value) {
      if (callbackFn) callbackFn();
    }
  }
  );
}

Vue.prototype.$http = axiosIns;

export default axiosIns;
