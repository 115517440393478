<template>
  <b-card>
    <!-- <g-form @submit="save"> -->
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center mb-2">
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img ref="previewEl" rounded height="80" width="80" :src="base64Image || imageUrl" />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
              {{ $t("change") }}
            </b-button>
            <b-form-file v-model="image" ref="refInputEl" accept=".jpg, .png, .gif" :hidden="true" plain />
            <!--/ upload button -->
          </b-media-body>
        </b-media>
        <!--/ media -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.code" label-text="code" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <!-- arabicName  -->
        <g-field rules="required" id="arabicName" ref="arabicName" :value.sync="selectedItem.arabicName" name="arabicName"
          label-text="arabicName" />
      </b-col>
      <b-col md="4">
        <g-field id="englishName" ref="englishName" :value.sync="selectedItem.englishName" name="englishName"
          label-text="englishName" />
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.userName" label-text="userName" rules="required|userName" ref="userName" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.passwordHash" type="password" label-text="password" rules="required" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="passwordHashConfirm" type="password" label-text="confirmPassword" />
          <!-- danger -->
          <b-alert variant="danger" :show="passwordHashConfirm !== selectedItem.passwordHash">
            <div class="alert-body">
              <span>{{ $t("passwordDonotMatched") }}</span>
            </div>
          </b-alert>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.email" label-text="email" rules="email|required" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.phone" label-text="phone" />
        </b-form-group>
      </b-col>
      <b-col md="4" v-if="selectedItem.isAdmin === false">
        <b-form-group>
          <g-field :value.sync="selectedBranches" :dir="isRight ? 'rtl' : 'ltr'" :options="branches" label-text="branches"
            field="select" label="arabicName" :rules="selectedItem.isAdmin ? '' : 'required'" multiple />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.maxAllowedDiscount" label-text="maxAllowedDiscount" type="number"
            rules="min_value:0|max_value:100" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedItem.paymentMethodType" :dir="isRight ? 'rtl' : 'ltr'"
            label-text="defaultPaymentMethod" field="select" name="paymentMethodType" :options="paymentWays"
            label="arabicName" @input="(v) => {
              if (v === null) {
                selectedItem.safeId = null;
                selectedItem.paymentMethodId = null;
              }
            }" @change="(v) => {
  methodTypeName = isRight ? v.arabicName : v.englishName;
  // changedTypes(v.id)
  if (selectedItem.paymentMethodType === 'safe') {
    selectedItem.paymentMethodId = null;
  }
  if (selectedItem.paymentMethodType === 'other') {
    selectedItem.safeId = null;
  }
}" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field :value.sync="selectedStages" :dir="isRight ? 'rtl' : 'ltr'" label-text="userStages" field="select"
            name="userStages" multiple :options="stages" label="arabicName" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group :label="$t('enablePaymentMachen')">
          <b-form-checkbox
            v-model="selectedItem.enablePaymentMachen"
            class="custom-control-primary"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group :label="$t('isActive')">
        <b-form-checkbox v-model="selectedItem.isActive" :disabled="selectedItem.isAdmin"
        class="custom-control-primary">
      </b-form-checkbox>
    </b-form-group>
    </b-col>
    </b-row><b-row>
      <b-col md="2">
      <b-form-checkbox v-model="selectedItem.isAdmin"
        class="custom-control-primary mt-1 mb-2">
        {{ $t("isAdmin") }}
      </b-form-checkbox>
    </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-show="!selectedItem.isAdmin" md="5" class="medium-window">
        <permissions ref="perComponent" :key="selectedItem.id" :user-permissions="selectedItem.permissions" @update-items="(val) => {
            items = val;
          }
          " />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end sticky-bottom">
        <b-button class="mx-1" @click="save" variant="primary" data-action-type="save"
          v-permission="$route.meta.permission" :disabled="passwordHashConfirm !== selectedItem.passwordHash ||
            !new RegExp('^[a-zA-Z0-9_]+$').test(selectedItem.userName)
            ">
          {{ $t("save") }}
        </b-button>
      </b-col>
    </b-row>
    <!-- </g-form> -->
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { paymentWays } from '@/libs/acl/Lookups';
import permissions from './components/Permissions.vue';

export default {
  components: {
    permissions,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      stages: [],
      paymentWays: paymentWays,
      selectedGroubs: [],
      selectedBranches: [],
      selectedStages: [],
      image: null,
      imageUrl: '',
      passwordHashConfirm: '',
      items: [],
      accessGroups: [],
      selectedItem: {
        id: 0,
        englishName: '',
        code: '',
        userName: '',
        passwordHash: '',
        isAdmin: false,
        isActive: true,
        permissions: [],
        branches: [],
        imageUrl: '',
        phone: '',
        email: '',
        base64Image: '',
        imageExtension: '',
        maxAllowedDiscount: 0,
        stages: []
      },
      base64Image: '',
      currentUser: localStorage.USERID,
    };
  },
 async mounted() {
    // this.getAccessGroups();
    this.getStages();
    if (this.id > 0) {
     await this.get({ url: 'Users', id: this.id }).then((data) => {
        // const brachesIds = data.accessGroups.map((b) => b.accessGroupId);
        const bracheIds = data.branches.map((b) => b.branchId);
        this.selectedBranches = this.branches.filter((b) => bracheIds.indexOf(b.id) > -1);
        // this.selectedGroubs = this.accessGroups.filter(
        //   (b) => brachesIds.indexOf(b.id) > -1
        // );
        const stageIds = data.stages.map((b) => b.stageId);
        this.selectedStages = this.stages.filter((b) => stageIds.indexOf(b.id) > -1);
        this.selectedItem = data;
        this.passwordHashConfirm = this.selectedItem.passwordHash;
        this.imageUrl = this.selectedItem.imageUrl
          ? `${this.baseUrl}${this.selectedItem.imageUrl}`
          : 'no_image.png';
      });
    } else {
      this.imageUrl = 'no_image.png';
    }
  },
  watch: {
    image(newVal) {
      this.selectedItem.imageExtension = newVal.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(newVal);
      reader.onload = () => {
        this.selectedItem.base64Image = reader.result.split(',').pop();
        this.base64Image = reader.result;
      };
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    // getAccessGroups() {
    //   this.get({ url: 'AccessGroups' }).then((data) => {
    //     this.accessGroups = data
    //   });
    // },
    getStages() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data
      });
    },
    save() {
      if (this.selectedItem.maxAllowedDiscount > 100) {
        this.doneAlert({
          text: this.$t('maxAllowedDiscountHandredPercent'),
          type: 'error'
        });
      } else {
        if (!this.selectedItem.isAdmin) {
          this.selectedItem.permissions = this.items.reduce(
            (groupPermissions, permission) => {
              groupPermissions.push(
                ...permission.children
                  .filter((p) => p.selected)
                  .map((p) => ({
                    userId: this.profile.userId,
                    permissionId: p.id,
                  }))
              );
              return groupPermissions;
            },
            []
          );
        } else {
          this.selectedItem.permissions = [];
          this.selectedItem.branches = [];
          this.selectedItem.stages = [];
        }
        this.selectedItem.accessGroups = this.selectedGroubs.map((item) => {
          return {
            accessGroupId: item.id,
            ...item
          };
        });
        this.selectedItem.branches = this.selectedBranches.map((item) => {
          return {
            branchId: item.id,
            ...item,
          };
        });
        this.selectedItem.stages = this.selectedStages.map((item) => {
          return {
            stageId: item.id,
            ...item,
          };
        });

        (this.id > 0
          ? this.update({
            url: 'users',
            id: this.selectedItem.id,
            data: this.selectedItem,
          })
          : this.create({ url: 'users', data: this.selectedItem })
        ).then(() => {
          this.doneAlert({
            text:
              this.id > 0
                ? this.$t('updatedSuccessfully')
                : this.$t('savedSuccessfully'),
          });
          if (this.id > 0 && this.id === Number(this.currentUser)) {
            this.logout()
          } else {
            this.$router.push({ name: 'users' });
          }
        });
      }
    },
  }
};
</script>

<style></style>
