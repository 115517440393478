import permissions from './permissionLookups';

const paymentWays = [
  {
    id: 'cash',
    englishName: 'cash',
    arabicName: 'نقدي',
  },
  {
    id: 'other',
    englishName: 'mada',
    arabicName: 'اخري',
  },
];
const months = [
  {
    id: 1,
    arabicName: '1 - يناير',
    englishName: '1 - January'
  },
  {
    id: 2,
    arabicName: '2 - فبراير',
    englishName: '2 - February'
  },
  {
    id: 3,
    arabicName: '3 - مارس',
    englishName: '3 - March'
  },
  {
    id: 4,
    arabicName: '4 - إبريل',
    englishName: '4 - March'
  },
  {
    id: 5,
    arabicName: '5 - مايو',
    englishName: '5 - May'
  },
  {
    id: 6,
    arabicName: '6 - يونيو',
    englishName: '6 - June'
  },
  {
    id: 7,
    arabicName: '7 - يوليو',
    englishName: '7 - July'
  },
  {
    id: 8,
    arabicName: '8 - أغسطس',
    englishName: '8 - August'
  },
  {
    id: 9,
    arabicName: '9 - سبتمبر',
    englishName: '9 - September'
  },
  {
    id: 10,
    arabicName: '10 - أكتوبر',
    englishName: '10 - October'
  },
  {
    id: 11,
    arabicName: '11 - نوفمبر',
    englishName: '11 - November'
  },
  {
    id: 12,
    arabicName: '12 - ديسمبر',
    englishName: '12 - December'
  },
]
const years = [
  {
    id: 2023,
    arabicName: '2023'
},
{
    id: 2024,
    arabicName: '2024'
},
{
    id: 2025,
    arabicName: '2025'
},
{
    id: 2026,
    arabicName: '2026'
},
{
    id: 2027,
    arabicName: '2027'
},
{
    id: 2028,
    arabicName: '2028'
},
{
    id: 2029,
    arabicName: '2029'
},
{
    id: 2030,
    arabicName: '2030'
},
{
    id: 2031,
    arabicName: '2031'
},
{
    id: 2032,
    arabicName: '2032'
},
{
    id: 2033,
    arabicName: '2033'
},
{
    id: 2034,
    arabicName: '2034'
},
{
    id: 2035,
    arabicName: '2035'
},
{
    id: 2036,
    arabicName: '2036'
},
{
    id: 2037,
    arabicName: '2037'
},
{
    id: 2038,
    arabicName: '2038'
},
{
    id: 2039,
    arabicName: '2039'
},
{
    id: 2040,
    arabicName: '2040'
},
{
    id: 2041,
    arabicName: '2041'
},
{
    id: 2042,
    arabicName: '2042'
},
{
    id: 2043,
    arabicName: '2043'
},
{
    id: 2044,
    arabicName: '2044'
},
{
    id: 2045,
    arabicName: '2045'
},
{
    id: 2046,
    arabicName: '2046'
},
{
    id: 2047,
    arabicName: '2047'
},
{
    id: 2048,
    arabicName: '2048'
},
{
    id: 2049,
    arabicName: '2049'
},
{
    id: 2050,
    arabicName: '2050'
},
{
    id: 2051,
    arabicName: '2051'
},
{
    id: 2052,
    arabicName: '2052'
},
{
    id: 2053,
    arabicName: '2053'
},
{
    id: 2054,
    arabicName: '2054'
},
{
    id: 2055,
    arabicName: '2055'
},
{
    id: 2056,
    arabicName: '2056'
},
{
    id: 2057,
    arabicName: '2057'
},
{
    id: 2058,
    arabicName: '2058'
},
{
    id: 2059,
    arabicName: '2059'
},
{
    id: 2060,
    arabicName: '2060'
},
{
    id: 2061,
    arabicName: '2061'
},
{
    id: 2062,
    arabicName: '2062'
},
{
    id: 2063,
    arabicName: '2063'
},
{
    id: 2064,
    arabicName: '2064'
},
{
    id: 2065,
    arabicName: '2065'
},
{
    id: 2066,
    arabicName: '2066'
},
{
    id: 2067,
    arabicName: '2067'
},
{
    id: 2068,
    arabicName: '2068'
},
{
    id: 2069,
    arabicName: '2069'
},
{
    id: 2070,
    arabicName: '2070'
},
{
    id: 2071,
    arabicName: '2071'
},
{
    id: 2072,
    arabicName: '2072'
},
{
    id: 2073,
    arabicName: '2073'
},
{
    id: 2074,
    arabicName: '2074'
},
{
    id: 2075,
    arabicName: '2075'
},
{
    id: 2076,
    arabicName: '2076'
},
{
    id: 2077,
    arabicName: '2077'
},
{
    id: 2078,
    arabicName: '2078'
},
{
    id: 2079,
    arabicName: '2079'
},
{
    id: 2080,
    arabicName: '2080'
},
{
    id: 2081,
    arabicName: '2081'
},
{
    id: 2082,
    arabicName: '2082'
},
{
    id: 2083,
    arabicName: '2083'
},
{
    id: 2084,
    arabicName: '2084'
},
{
    id: 2085,
    arabicName: '2085'
},
{
    id: 2086,
    arabicName: '2086'
},
{
    id: 2087,
    arabicName: '2087'
},
{
    id: 2088,
    arabicName: '2088'
},
{
    id: 2089,
    arabicName: '2089'
},
{
    id: 2090,
    arabicName: '2090'
},
{
    id: 2091,
    arabicName: '2091'
},
{
    id: 2092,
    arabicName: '2092'
},
{
    id: 2093,
    arabicName: '2093'
},
{
    id: 2094,
    arabicName: '2094'
},
{
    id: 2095,
    arabicName: '2095'
},
{
    id: 2096,
    arabicName: '2096'
},
{
    id: 2097,
    arabicName: '2097'
},
{
    id: 2098,
    arabicName: '2098'
},
{
    id: 2099,
    arabicName: '2099'
},
{
    id: 2100,
    arabicName: '2100'
},
{
    id: '2101',
    arabicName: '2101'
},
{
    id: '2102',
    arabicName: '2102'
},
{
    id: '2103',
    arabicName: '2103'
},
{
    id: '2104',
    arabicName: '2104'
},
{
    id: '2105',
    arabicName: '2105'
},
{
    id: '2106',
    arabicName: '2106'
},
{
    id: '2107',
    arabicName: '2107'
},
{
    id: '2108',
    arabicName: '2108'
},
{
    id: '2109',
    arabicName: '2109'
},
{
    id: '2110',
    arabicName: '2110'
},
{
    id: '2111',
    arabicName: '2111'
},
{
    id: '2112',
    arabicName: '2112'
},
{
    id: '2113',
    arabicName: '2113'
},
{
    id: '2114',
    arabicName: '2114'
},
{
    id: '2115',
    arabicName: '2115'
},
{
    id: '2116',
    arabicName: 2116
},
{
    id: '2117',
    arabicName: '2117'
},
{
    id: '2118',
    arabicName: '2118'
},
{
    id: '2119',
    arabicName: '2119'
},
{
    id: '2120',
    arabicName: '2120'
},
{
    id: '2121',
    arabicName: '2121'
},
{
    id: '2122',
    arabicName: '2122'
}
]
const benefitsAndDeductionsTypes = [
  {
   id: 'benefit',
   englishName: 'benefit',
   arabicName: 'استحقاق',
  },
  {
    id: 'deduction',
    englishName: 'deduction',
    arabicName: 'استقطاع',
  }
];
const userPaymentWays = [
  {
    id: 'safe',
    englishName: 'Safe',
    arabicName: 'خزينة',
  },
  // {
  //   id: 'bank',
  //   englishName: 'Bank',
  //   arabicName: 'بنك',
  // },
  // {
  //   id: 'cheque',
  //   englishName: 'cheque',
  //   arabicName: 'شيك',
  // },
  {
    id: 'other',
    englishName: 'mada',
    arabicName: 'اخري',
  },
];

const InvoiceType = [
  {
    id: 'Sales',
    englishName: 'Sales',
    arabicName: 'فاتورة مبيعات',
  },

  {
    id: 'ReturnSales',
    englishName: 'Return Sales',
    arabicName: 'فاتورة مرتجع مبيعات',
  }

]

const illnessHistoryInFamily = [
  {
    id: 'fatherSide',
    arabicName: 'جهة الأب',
    englishName: 'fatherSide'
  },
  {
    id: 'motherSide',
    arabicName: 'جهة الأم',
    englishName: 'motherSide'
  }
];

const suffersFromAnAllergy = [
  {
    id: 'food',
    arabicName: 'الطعام',
    englishName: 'food'
  },
  {
    id: 'medicine',
    arabicName: 'الدواء',
    englishName: 'medicine'
  }
];

const roleOfGrandparents = [
  {
    id: 'grandparentsForFather',
    arabicName: 'الجد والجدة للآب ',
    englishName: 'GrandparentsForFather',
  },
  {
    id: 'grandparentsForMather',
    arabicName: 'الجد والجدة الأم',
    englishName: 'GrandparentsForMather',
  },
];
const describeChild = [
  {
    id: 'passionate',
    arabicName: 'عاطفى',
    englishName: 'passionate',
  },
  {
    id: 'logical',
    arabicName: 'منطقى',
    englishName: 'logical',
  },
  {
    id: 'leading',
    arabicName: 'قيادي',
    englishName: 'leading',
  },
  {
    id: 'creative',
    arabicName: 'مبدع',
    englishName: 'creative',
  },
];

const ItemTransactionPaymentType = [
  {
    id: 'cash',
    arabicName: 'نقدي',
    englishName: 'Cash',
  },
  {
    id: 'credit',
    arabicName: 'آجل',
    englishName: 'Credit',
  },
];

const genders = [
  {
    id: 'male',
    arabicName: 'ذكر',
    englishName: 'male',
  },
  {
    id: 'female',
    arabicName: 'انثى',
    englishName: 'female',
  },
];

const busSubscriptionCategory = [
  {
    id: 'go',
    arabicName: 'ذهاب',
    englishName: 'go',
  },
  {
    id: 'back',
    arabicName: 'اياب',
    englishName: 'back',
  },
  {
    id: 'both',
    arabicName: 'ذهاب واياب',
    englishName: 'both',
  }
];

const childLivesWith = [
  {
    id: 'parents',
    arabicName: 'الوالدين',
    englishName: 'parents',
  },
  {
    id: 'fatherOnly',
    arabicName: 'الأب فقط',
    englishName: 'fatherOnly',
  },
  {
    id: 'motherOnly',
    arabicName: 'الأم فقط',
    englishName: 'motherOnly'
  },
  {
    id: 'others',
    arabicName: 'آخرون',
    englishName: 'others'
  }
];

const branchPrem = [
  {
    id: 'none',
    arabicName: 'سماح',
    englishName: 'none',
  },
  {
    id: 'warning',
    arabicName: 'تحذير',
    englishName: 'warning',
  },
  {
    id: 'error',
    arabicName: 'منع',
    englishName: 'error ',
  },
];

const rows = [
  {
    id: 1,
    arabicName: 'الصف الأول',
    englishName: 'First Grade',
  },
  {
    id: 2,
    arabicName: 'الصف الثاني',
    englishName: 'Second Grade',
  },
  {
    id: 3,
    arabicName: 'الصف الثالث',
    englishName: 'Third Grade',
  },
  {
    id: 4,
    arabicName: 'الصف الرابع',
    englishName: 'Fourth Grade',
  },
  {
    id: 5,
    arabicName: 'الصف الخامس',
    englishName: 'Fifth Grade',
  },
  {
    id: 6,
    arabicName: 'الصف السادس',
    englishName: 'Sixth Grade',
  },
];

const guardianRelationships = [
  {
    id: 'father',
    arabicName: 'الأب',
    englishName: 'Father',
  },
  {
    id: 'mother',
    arabicName: 'الأم',
    englishName: 'Mother',
  },
  {
    id: 'brother',
    arabicName: 'الأخ',
    englishName: 'Prother',
  },
  {
    id: 'uncle',
    arabicName: 'العم',
    englishName: 'Uncle',
  },
  {
    id: 'mole',
    arabicName: 'الخال',
    englishName: 'mole',
  },
  {
    id: 'other',
    arabicName: 'اخري',
    englishName: 'Other',
  },
];

const relativeRelation = [
  {
    id: 'son',
    arabicName: 'ابن',
    englishName: 'Son',
  },
  {
    id: 'daughter',
    arabicName: 'ابنة',
    englishName: 'Daughter',
  },
  {
    id: 'husband',
    arabicName: 'زوج',
    englishName: 'Husband',
  },
  {
    id: 'wife',
    arabicName: 'زوجة',
    englishName: 'Wife',
  },
  {
    id: 'other',
    arabicName: 'اخري',
    englishName: 'Other',
  },

];

const valuesType = [
  {
    id: 'percentage',
    arabicName: 'نسبه',
    englishName: 'percentage',
  },
  {
    id: 'value',
    arabicName: 'قيمه',
    englishName: 'value',
  },
];

const salaryItemTypes = [
  {
    id: 'earning',
    arabicName: 'إستحقاقات',
    englishName: 'earning',
  },
  {
    id: 'deduction',
    arabicName: 'إستقطاعات',
    englishName: 'deduction',
  },
];

const transactionsTypes = [
  {
    id: 'exchange',
    arabicName: 'صرف',
    englishName: 'exchange',
  },
  {
    id: 'return',
    arabicName: 'إرجاع',
    englishName: 'return',
  },
];
const salaryItemNatures = [
  {
    id: 'percentage',
    arabicName: 'نسبة مئوية',
    englishName: 'percentage',
  },
  {
    id: 'value',
    arabicName: 'قيمة',
    englishName: 'value',
  },
  {
    id: 'days',
    arabicName: 'أيام',
    englishName: 'days',
  },
  {
    id: 'hours',
    arabicName: 'ساعات',
    englishName: 'hours',
  },
];
const AccountTypes = [
  {
    id: 'main',
    arabicName: 'رئيسى',
    englishName: 'main',
  },
  {
    id: 'sub',
    arabicName: 'فرعي',
    englishName: 'sub',
  },
];

const AccountNatures = [
  {
    id: 'debit',
    arabicName: 'مدين',
    englishName: 'debit',
  },
  {
    id: 'credit',
    arabicName: 'دائن',
    englishName: 'credit',
  },
];

const FinalAccountTypes = [
  {
    id: 'incomeList',
    arabicName: 'رصيد ختامي',
    englishName: 'incomeList',
  },
  {
    id: 'budget',
    arabicName: 'ميزانيه',
    englishName: 'budget',
  },
];

const vouchersOptions = [
  {
    id: 'true',
    arabicName: 'القيود الملغيه',
    englishName: 'true',
  },
  {
    id: 'false',
    arabicName: 'القيود الغير ملغيه',
    englishName: 'false',
  },
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'all',
  },
];
const vouchersOptionsPosting = [
  {
    id: 'true',
    arabicName: 'مرحل',
    englishName: 'false',
  },
  {
    id: 'false',
    arabicName: 'يدوي',
    englishName: 'false',
  },
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'all',
  },
];

const costCenterGroupByList = [
  {
    id: 'Account',
    arabicName: 'الحساب',
    englishName: 'Account',
  },
  {
    id: 'CostCenter',
    arabicName: 'مراكز التكلفة',
    englishName: 'CostCenter',
  },
];

const identityTypes = [
  {
    id: 'nationalIdentity',
    arabicName: 'هوية وطنية',
    englishName: 'National Identity',
  },
  {
    id: 'residence',
    arabicName: 'إقامة',
    englishName: 'Residence',
  },
];

const invoiceValidationTypes = {
  salesPrice: 'salesPrice',
  balance: 'balance',
};

const educationQualifications = [
  {
    id: 'middle',
    arabicName: 'مؤهل متوسط',
    englishName: 'Middle Qualification'
  },
  {
    id: 'bachelor',
    arabicName: 'بكالريوس',
    englishName: "Bachelor's Degree"
  },
  {
    id: 'master',
    arabicName: 'ماجستير',
    englishName: 'Mastrer Qualification'
  },
  {
    id: 'phd',
    arabicName: 'دكتوراة',
    englishName: 'Ph.D Qualification'
  },
  {
    id: 'other',
    arabicName: 'أخري',
    englishName: 'Other'
  }
];

const busStatus = [
  {
    id: 'empty',
    arabicName: 'فارغ',
    englishName: 'Empty',
    color: '#7367f0'
  },
  {
    id: 'contain',
    arabicName: 'غير مكتمل',
    englishName: 'Un-Completed',
    color: '#28c76f'
  },
  {
    id: 'complete',
    arabicName: 'مكتمل',
    englishName: 'Complete',
    color: '#ea5455'
  }
];

const schoolRecognitionMethods = [
  {
    id: 'facebook',
    arabicName: 'فيس بوك',
    englishName: 'Facebook'
  },
  {
    id: 'twitter',
    arabicName: 'تويتر',
    englishName: 'Twitter'
  },
  {
    id: 'instagram',
    arabicName: 'انستجرام',
    englishName: 'Instagram'
  },
  {
    id: 'google',
    arabicName: 'جوجل',
    englishName: 'Google'
  },
  {
    id: 'friend',
    arabicName: 'صديق',
    englishName: 'Friend'
  },
  {
    id: 'schoolLocation',
    arabicName: 'الموقع الجغرافي للمدرسة',
    englishName: 'School Location'
  },
  {
    id: 'studentOrGuardiantAtSchool',
    arabicName: 'طالب / ولي أمر بالمدرسة',
    englishName: 'Student / Guardiant at School'
  },
  {
    id: 'other',
    arabicName: 'أخرى',
    englishName: 'Other'
  }

];

const reportParameters = [
  {
    id: 'allStudents',
    arabicName: 'كل الطلاب',
    englishName: 'all Students'
  },
  {
    id: 'allStages',
    arabicName: 'كل المراحل',
    englishName: 'all Stages'
  },
  {
    id: 'allRows',
    arabicName: 'كل الصفوف',
    englishName: 'all Rows'
  },
  {
    id: 'allClassrooms',
    arabicName: 'كل الفصول',
    englishName: 'all Classrooms'
  },
  {
    id: 'allServices',
    arabicName: 'كل الخدمات',
    englishName: 'all Services'
  },
  {
    id: 'notRelatedToPeriod',
    arabicName: 'غير محدد بفترة',
    englishName: 'notRelatedToPeriod'
  },
  {
    id: 'allDiscounts',
    arabicName: 'كل الخصومات',
    englishName: 'all Discounts'
  },
  {
    id: 'allGuardians',
    arabicName: 'كل أولياء الأمور',
    englishName: 'all Guardians'
  },
  {
    id: 'allBanks',
    arabicName: 'كل البنوك',
    englishName: 'all Banks'
  },
  {
    id: 'allSafes',
    arabicName: 'كل الخزن',
    englishName: 'all Safes'
  },
  {
    id: 'allIncomes',
    arabicName: 'كل الإيرادات',
    englishName: 'all Incomes'
  },
  {
    id: 'allExpenses',
    arabicName: 'كل المصروفات',
    englishName: 'all Expenses'
  },
  {
    id: 'allUsers',
    arabicName: 'كل المستخدمين',
    englishName: 'all Users'
  },
  {
    id: 'allPaymentMethods',
    arabicName: 'كل طرق الدفع',
    englishName: 'all Payment Methods'
  },
  {
    id: 'allBanksAndSafes',
    arabicName: 'كل البنوك والخزن',
    englishName: 'all Banks And Safes'
  },
  {
    id: 'allSuppliers',
    arabicName: 'كل الموردين',
    englishName: 'all Suppliers'
  },
  {
    id: 'allBranches',
    arabicName: 'كل الفروع',
    englishName: 'all Branches'
  },
  {
    id: 'allEmployees',
    arabicName: 'كل الموظفيين',
    englishName: 'all Employees'
  },
  {
    id: 'allDepartments',
    arabicName: 'كل الإدارات',
    englishName: 'all Departments'
  },
  {
    id: 'allDivisions',
    arabicName: 'كل الأقسام',
    englishName: 'all Divisions'
  },
  {
    id: 'allStatuses',
    arabicName: 'كل الحالات',
    englishName: 'all Statuses'
  },
  {
    id: 'allJobs',
    arabicName: 'كل الوظائف',
    englishName: 'all Jobs'
  },
];

const taxesStatus = [
  {
    id: 'taxable',
    arabicName: 'خاضع للضـريبة',
    englishName: 'taxable',
  },
  {
    id: 'nonTaxable',
    arabicName: 'غيـر خاضع للضـريبة',
    englishName: 'non Taxable',
  }
];

const transactionTypes = [
  {
    id: 'sale',
    arabicName: 'فاتورة مبيعات',
    englishName: 'Sales Invoice'
  },
  {
    id: 'return',
    arabicName: 'مرتجع مبيعات',
    englishName: 'Sales Returns'
  },
  {
    id: 'discount',
    arabicName: 'خصم لطالب',
    englishName: 'Student Discount'
  },
  {
    id: 'expense',
    arabicName: 'حركة مصروف',
    englishName: 'Expense Transaction'
  },
  {
    id: 'voucher',
    arabicName: 'قيد يومي',
    englishName: 'Dialy Voucher'
  },
  {
    id: 'income',
    arabicName: 'حركة إيراد',
    englishName: 'Income Transaction'
  },
];

const reports = [
  {
    title: 'invoiceReport',
    route: 'invoice-report',
    permission: 'reviewSalesDetailsReport',
  },
  {
    title: 'invoiceDetailsReport',
    route: 'invoice-details-report',
    permission: 'reviewInvoiceDetailsReport',
  },
  {
    title: 'restaurantReport',
    route: 'restaurant-report',
    permission: 'reviewRestaurantReport',
  },
  {
    title: 'purchaseReport',
    route: 'purchase-details-report',
    permission: 'reviewPurchaseDetailsReport',
  },
  {
    title: 'purchaseDetailsReport',
    route: 'purchase-report',
    permission: 'reviewPurchaseReport',
  },
  {
    title: 'customerSalesReport',
    route: 'customer-sales-report',
    permission: 'reviewCustomerSalesReport',
  },
  {
    title: 'salesItemByCustomerReport',
    route: 'sales-item-customer-report',
    permission: 'reviewSalesItemByCustomerReport',
  },
  { title: 'supplierPurchaseReport', route: 'supplier-purchase-report' },
  { title: 'itemPriceOverallReport', route: 'item-price-overall-report' },
  { title: 'itemsMinimumReport', route: 'items-minimum-report' },
  {
    title: 'itemsWithLimitExceededReport',
    route: 'items-with-limitExceeded-report',
  },
  {
    title: 'itemTransactionsTotalsReport',
    route: 'itemTransactions-totals-report',
  },
  {
    title: 'itemTransactionsDetailsReport',
    route: 'itemTransactions-details-report',
  },
  { title: 'storeTransfersReport', route: 'store-transfers-report' },
  { title: 'storeEvalutionReport', route: 'store-evalution-report' },
  {
    title: 'StoreItemsTotalBalanceReport',
    route: 'store-items-total-balance-report',
  },
  { title: 'storeBalanceReport', route: 'store-balance-report' },
  { title: 'taxesReport', route: 'taxes-report' },
  {
    title: 'customerAccountStatement',
    route: 'customer-account-statement-report',
  },
  {
    title: 'supplierAccountStatement',
    route: 'supplier-account-statement-report',
  },
  { title: 'customerDebtAgingReport', route: 'customer-debt-aging-report' },
  { title: 'supplierDebtAgingReport', route: 'supplier-debt-aging-report' },
  { title: 'itemBarCodeReport', route: 'item-barCode-report' },
  {
    title: 'accountStatementDetails',
    route: 'account-statement-details-report',
  },
  { title: 'supplierExpenseReport', route: 'supplier-expenses-report' },
  { title: 'bankBalanceReport', route: 'bank-balance-report' },
  { title: 'trialBalance', route: 'trial-balance-report' },
  { title: 'costCenterReport', route: 'cost-center-report' },
  { title: 'incomesReport', route: 'income-transactions-report' },
  { title: 'expensesReport', route: 'expense-transactions-report' },
  {
    title: 'collectionAllocationReport',
    route: 'collection-allocation-report',
  },
  { title: 'paymentAllocationReport', route: 'payment-allocation-report' },
  { title: 'allocatedSalesReport', route: 'allocated-sales-report' },
  { title: 'allocatedPurchReport', route: 'allocated-purch-report' },
  {
    title: 'customersWithLimitExceededReport',
    route: 'customers-with-limitExceeded-report',
  },
];
const studentsReports = [
  {
    title: 'students',
    route: 'students-report',
    icon: 'UsersIcon',
    permission: 'reviewStudentsReports',
  },
  {
    title: 'studentServies',
    route: 'studentServies-report',
    icon: 'FileTextIcon',
    permission: 'reviewStudentsReportsServices',
  },
  {
    title: 'studentDiscount',
    route: 'studentDiscount-report',
    icon: 'DollarSignIcon',
    permission: 'reviewStudentsReportsDiscounts',
  },
  {
    title: 'studentsAccountStatement',
    route: 'studentsAccountStatement-report',
    icon: 'FileTextIcon',
    permission: 'reviewAccountsStudentreports',
  },
  {
    title: 'studentStatuses',
    route: 'studentStatus-report',
    icon: 'FileTextIcon',
    permission: 'reviewStudentStatusesReport',

  },
  {
    title: 'reportStudentsSuccess',
    route: 'studentsSucess-report',
    icon: 'FileTextIcon',
    permission: 'reviewStudentsSuccessReport',
  }
];
const employeesReports = [
  {
    title: 'employees',
    route: 'employees-report',
    icon: 'UsersIcon',
    permission: 'viewEmployeesData'
  },
  {
    title: 'employeePayroll',
    route: 'employeePayroll-report',
    icon: 'FileTextIcon',
    permission: 'viewEmployeesPayslip'
  },
  {
    title: 'benefits',
    route: 'benefits-report',
    icon: 'FileTextIcon',
    permission: 'viewEmployeesBenefits'
  },
  {
    title: 'deductions',
    route: 'deductions-report',
    icon: 'FileTextIcon',
    permission: 'viewEmployeesDeductions'
  },
  {
    title: 'advancedPayments',
    route: 'advancedPayments-report',
    icon: 'FileTextIcon',
    permission: 'viewEmployeesAdvancedPayments'
  },
  {
    title: 'employeesData',
    route: 'employeesData-report',
    icon: 'UserIcon',
    permission: 'viewEmployeesData'
  },
  {
    title: 'employeesResidence',
    route: 'employeesResidence-report',
    icon: 'FileTextIcon',
    permission: 'viewEmployeesResidenceReport'
  }
];
const transactionReports = [

  {
    title: 'collectionVouchers',
    route: 'collectionVouchers-report',
    icon: 'FileIcon',
    permission: 'reviewCollectionTransactionsReport'
  },
  {
    title: 'paymentVouchers',
    route: 'paymentVouchers-report',
    icon: 'FileIcon',
    permission: 'reviewPaymentTransactionsReport'
  },
  {
    title: 'financialTransactionAllocation',
    route: 'financialTransactionAllocation-report',
    icon: 'FileIcon',
    permission: 'reviewReportFinancialTransactionAllocation'
  },
  {
    title: 'incomeTransaction',
    route: 'incomeTransaction-report',
    icon: 'FileTextIcon',
    permission: 'reviewIncomesReport'
  },
  {
    title: 'expenseTransaction',
    route: 'expenseTransaction-report',
    icon: 'FileTextIcon',
    permission: 'reviewExpensesReport'
  },
  {
    title: 'reportSalesAllocation',
    route: 'salesAllocation-report',
    icon: 'FileTextIcon',
    permission: 'reviewReportSalesAllocation'
  },
];

const accountsReports = [
  {
    title: 'accountStatementDetails',
    route: 'account-statement-details-report',
    icon: 'FileTextIcon',
    permission: 'reviewAccountStatementDetailsReport',
    isEnabled: true,
  },
  {
    title: 'trialBalance',
    route: 'trial-balance-report',
    icon: 'FileTextIcon',
    permission: 'reviewTrialBalanceReport',
    isEnabled: true,
  },
  {
    title: 'incomeStatementReport',
    route: 'income-statement-report',
    icon: 'FileTextIcon',
    permission: 'reviewIncomeStatementReport',
    isEnabled: true,
  },
  {
    title: 'bankAccountStatment',
    route: 'bankAccountStatment-report',
    icon: 'FileTextIcon',
    permission: 'reviewBankBalanceReport',
  },
  {
    title: 'safeAccountStatement',
    route: 'safeAccountStatement-report',
    icon: 'FileTextIcon',
    permission: 'reviewCashBoxAccountStatementReport',
  },
  {
    title: 'additionalValueTaxes',
    route: 'taxes-report',
    icon: 'FileTextIcon',
    permission: 'taxesReport',
  },
  {
    title: 'supplierExpensesReport',
    route: 'supplierExpenses-report',
    icon: 'FileTextIcon',
    permission: 'reviewSuppliersExpensesReport',
  },
  {
    title: 'balanceSheetReport',
    route: 'balance-sheet-report',
    icon: 'FileTextIcon',
    permission: 'reviewBalanceSheetReport',
    isEnabled: true,
  },
  {
    title: 'costCenterReport',
    route: 'costCenter-report',
    icon: 'FileTextIcon',
    permission: 'reviewCostCenterReport',
  }
];
const itemsReports = [
  {
    title: 'itemTransactionsTotalsReport',
    route: 'itemTransactions-totals-report',
    icon: 'FileTextIcon',
    permission: 'reviewItemTransactionsTotalsReport'
  },
  {
    title: 'itemTransactionsDetailsReport',
    route: 'itemTransactions-totals-report',
    icon: 'FileTextIcon',
    permission: 'reviewItemTransactionsDetailsReport'
  },
  {
    title: 'storeBalanceReport',
    route: 'store-balance-report',
    icon: 'FileTextIcon',
    permission: 'reviewStoreBalanceReport'
  },
  {
    title: 'storeEvalutionReport',
    route: 'store-evalution-report',
    icon: 'FileTextIcon',
    permission: 'reviewStoreEvalutionReport'
  },
  {
    title: 'inventoryReport',
    route: 'inventory-report',
    icon: 'FileTextIcon',
    permission: 'reviewInventoryReport'
  }
];
const carConditions = [
  {
    id: 'new',
    arabicName: 'جديد',
    englishName: 'new',
  },
  {
    id: 'used',
    arabicName: 'مستعمل',
    englishName: 'used',
  },
  {
    id: 'hulk',
    arabicName: 'متهالك',
    englishName: 'hulk',
  },
];
const cementTypesOptions = [
  {
    id: 'rawMaterial',
    arabicName: 'مادة خام',
    englishName: 'raw material',
  },
  {
    id: 'perfectlyMade',
    arabicName: 'تام الصنع',
    englishName: 'Perfectly made',
  },
];
const itemsTypes = [
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
  {
    id: 1,
    arabicName: 'اصناف موجبة',
    englishName: 'positive items',
  },
  {
    id: -1,
    arabicName: 'اصناف سالبة',
    englishName: 'negative items',
  },
  {
    id: 0,
    arabicName: 'اصناف صفرية',
    englishName: 'Zero items',
  }
];
const offersAndDiscounts = [
  {
    value: 'offers',
    text: 'عروض',
    englishName: 'offers',
  },
  {
    value: 'discounts',
    text: 'خصومات',
    englishName: 'discounts',
  }
];
const purchInvoiceFilters = [
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
  {
    id: 2,
    arabicName: 'مدفوع',
    englishName: 'POS Invoice',
  },
  {
    id: 0,
    arabicName: 'غير مدفوع',
    englishName: 'Sales Order',
  },
  {
    id: 1,
    arabicName: 'مدفوع جزء',
    englishName: '',
  }
];

const purchInvoiceTypes = [
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
  {
    id: 'purch',
    arabicName: 'فاتورة مشتريات',
    englishName: 'Purch Invoice',
  },
  // {
  //   id: 'purchOrder',
  //   arabicName: 'أمر شراء',
  //   englishName: 'Purch Order',
  // },
  // {
  //   id: 'returnPurch',
  //   arabicName: 'مرتجع مشتريات',
  //   englishName: 'Return Sales',
  // }
];

const storageTypes = [
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
  {
    id: 'exchangeOrder',
    arabicName: 'إذن صرف',
    englishName: 'Exchange Order',
  },
  {
    id: 'additionOrder',
    arabicName: 'اذن استلام',
    englishName: 'Addition order',
  },
  // {
  //   id: 'manufacturing',
  //   arabicName: 'اذن تصنيع',
  //   englishName: 'manufacturing order',
  // },
  // {
  //   id: 'manufacturingExchangeOrder',
  //   arabicName: 'اذن صرف تصنيع',
  //   englishName: 'ManufacturingExchangeOrder',
  // }
];

const storageConcreteTypes = [
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
  {
    id: 'exchangeOrder',
    arabicName: 'إذن صرف',
    englishName: 'Exchange Order',
  },
  {
    id: 'concreteDelivery',
    arabicName: 'إذن تسليم خرسانة',
    englishName: 'concrete Order',
  },
  {
    id: 'additionOrder',
    arabicName: 'اذن استلام',
    englishName: 'Addition order',
  }
  // {
  //   id: 'manufacturing',
  //   arabicName: 'اذن تصنيع',
  //   englishName: 'manufacturing order',
  // },
  // {
  //   id: 'manufacturingExchangeOrder',
  //   arabicName: 'اذن صرف تصنيع',
  //   englishName: 'ManufacturingExchangeOrder',
  // },
];
const exchangeSalesMethods = [
  {
    id: 'customers',
    arabicName: 'العملاء',
    englishName: 'Customers',
  },
  {
    id: 'agents',
    arabicName: 'المندوبين',
    englishName: 'Agents',
  },
  {
    id: 'exchangeDestinations',
    arabicName: 'جهات الصرف',
    englishName: 'exchangeDest',
  }
];

const exchangePurchMethods = [
  {
    id: 'suppliers',
    arabicName: 'الموردين',
    englishName: 'Suppliers',
  },
  {
    id: 'agents',
    arabicName: 'المندوبين',
    englishName: 'Agents',
  },
  {
    id: 'exchangeDestinations',
    arabicName: 'جهات الصرف',
    englishName: 'exchangeDest',
  }
];

const stocktakingMethodTypes = [
  {
    id: 'periodic',
    englishName: 'Periodic stocktaking',
    arabicName: 'جرد دوري',
  },
  {
    id: 'continuous',
    englishName: 'Continuous stocktaking',
    arabicName: 'جرد مستمر',
  },
];

const invoiceSearchSettings = [
  {
    id: false,
    englishName: 'part',
    arabicName: 'جزء من النص',
  },
  {
    id: true,
    englishName: 'full',
    arabicName: 'كامل النص',
  },
];
const costPricesPolicy = [
  {
    id: 'pricesAverage',
    arabicName: 'متوسط السعر',
    englishName: 'pricesAverage',
  },
  {
    id: 'lastPrice',
    arabicName: 'اخر سعر',
    englishName: 'lastPrice ',
  },
];
const integrationTypes = [
  {
    id: 'test',
    englishName: 'Test',
    arabicName: 'تجريبى',
  },
  {
    id: 'production',
    englishName: 'Production',
    arabicName: 'فعلي',
  },
];

const invoiceTypes = [
  {
    id: 'simplified',
    englishName: 'Simplified',
    arabicName: 'مبسطة',
  },
  // {
  //   id: 'taxable',
  //   englishName: 'Taxable',
  //   arabicName: 'ضريبية',
  // },
  // {
  //   id: 'taxableAndSimplified',
  //   englishName: 'TaxableAndSimplified',
  //   arabicName: 'ضريبية و مبسطة',
  // },
]
const transactions = [
  {
    id: 'itemTransactions',
    englishName: 'itemTransactions',
    arabicName: 'الفواتير',
  },
  {
    id: 'voucher',
    englishName: 'voucher',
    arabicName: 'قيد يومية',
  }
];
const itemTransactionsTypes = [
  {
    id: 'Sales',
    arabicName: 'فاتورة مبيعات',
    englishName: 'POS Invoice',
  },
  {
    id: 'Purch',
    arabicName: 'فاتورة مشتريات',
    englishName: 'Purch Invoice',
  },
];

export {
  permissions,
  busStatus,
  paymentWays,
  months,
  years,
  benefitsAndDeductionsTypes,
  userPaymentWays,
  InvoiceType,
  ItemTransactionPaymentType,
  genders,
  branchPrem,
  busSubscriptionCategory,
  rows,
  guardianRelationships,
  relativeRelation,
  roleOfGrandparents,
  suffersFromAnAllergy,
  illnessHistoryInFamily,
  childLivesWith,
  describeChild,
  valuesType,
  salaryItemTypes,
  transactionsTypes,
  salaryItemNatures,
  AccountTypes,
  AccountNatures,
  FinalAccountTypes,
  reports,
  costCenterGroupByList,
  vouchersOptions,
  vouchersOptionsPosting,
  studentsReports,
  employeesReports,
  accountsReports,
  transactionReports,
  identityTypes,
  educationQualifications,
  schoolRecognitionMethods,
  reportParameters,
  transactionTypes,
  taxesStatus,
  invoiceValidationTypes,
  itemsTypes,
  offersAndDiscounts,
  carConditions,
  cementTypesOptions,
  purchInvoiceFilters,
  purchInvoiceTypes,
  storageTypes,
  storageConcreteTypes,
  exchangeSalesMethods,
  exchangePurchMethods,
  stocktakingMethodTypes,
  invoiceSearchSettings,
  costPricesPolicy,
  itemsReports,
  invoiceTypes,
  integrationTypes,
  transactions,
  itemTransactionsTypes
};
