export default [
    {
        path: '/discountsCollection',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
            {
                path: '',
                name: 'discountsCollection',
                component: () => import('@/pages/Procedures/discountsCollection/Edit.vue'),
                meta: {
                    pageTitle: 'discountsCollection',
                    breadcrumb: [{
                        text: 'procedures',
                        active: true
                    }],
                    permission: 'addDiscountCollection',
                },
            },
        ]
    },

    {
        path: '/messages',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
            {
                path: '',
                name: 'messages',
                component: () => import('@/pages/Procedures/messages/List.vue'),
                meta: {
                    pageTitle: 'messages',
                    breadcrumb: [{
                        text: 'procedures',
                        active: true
                    }],
                    permission: 'viewMessages',
                },
            },
            {
                path: 'new',
                name: 'messages-new',
                component: () => import('@/pages/Procedures/messages/Edit.vue'),
                meta: {
                    pageTitle: 'messages-new',
                    breadcrumb: [
                        { text: 'procedures', active: true },
                        { text: 'messages', active: true },
                    ],
                    permission: 'addMessages',
                },
            },
            {
                path: 'edit/:id',
                name: 'messages-edit',
                props: true,
                component: () => import('@/pages/Procedures/messages/Edit.vue'),
                meta: {
                    pageTitle: 'messages-edit',
                    breadcrumb: [
                        { text: 'procedures', active: true },
                        { text: 'messages', active: true },
                    ],
                    permission: 'editMessages',
                },
            },
        ],
    },
    {
        path: '/studentInvoices',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
            {
                path: 'new',
                name: 'studentInvoices',
                props: true,
                params: { isGroup: false },
                component: () => import('@/pages/Procedures/studentInvoices/Edit.vue'),
                meta: {
                    pageTitle: 'studentInvoices',
                    breadcrumb: [
                        { text: 'procedures', active: true },
                    ],
                    permission: 'addStudentInvoices',
                },
            },
        ]

    },

    {
        path: '/deservedFessCollection',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
            {
                path: '',
                name: 'deservedFeesCollection',
                component: () => import('@/pages/Procedures/deservedFeesCollection/Edit.vue'),
                meta: {
                    pageTitle: 'deservedFeesCollection',
                    breadcrumb: [
                        { text: 'procedures', active: true },
                    ],
                    permission: 'addDeservedFees',
                },
            },
        ]

    },

    {
        path: '/busAccommodation',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
          {
            path: '',
            name: 'busAccommodation',
            component: () => import('@/pages/Procedures/busAccommodation/Edit.vue'),
            meta: {
              pageTitle: 'busAccommodation',
              breadcrumb: [{ text: 'procedures', active: true }],
              permission: 'addBusAccommodation'
            },
          },
        ],
    },

    {
        path: '/studentSuccess',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
          {
            path: '',
            name: 'studentSuccess',
            component: () => import('@/pages/Procedures/studentSuccess/Edit.vue'),
            meta: {
              pageTitle: 'studentSuccess',
              breadcrumb: [{ text: 'procedures', active: true }],
              permission: 'createStudentSuccess'
            },
          },
        ],
    },

    {
        path: '/temporaryStudents',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
            {
                path: '',
                name: 'temporaryStudents',
                component: () => import('@/pages/Procedures/TemporaryStudents/List.vue'),
                meta: {
                    pageTitle: 'temporaryStudents',
                    breadcrumb: [{
                        text: 'procedures',
                        active: true
                    }],
                    permission: 'viewRegistrationRequests',
                },
            },
            {
                path: 'edit/:id',
                name: 'temporaryStudents-edit',
                props: true,
                component: () => import('@/pages/Procedures/TemporaryStudents/Edit.vue'),
                meta: {
                    pageTitle: 'viewStudentRegistrationDetails',
                    breadcrumb: [
                        { text: 'procedures', active: true },
                        { text: 'temporaryStudents', active: true },
                    ],
                    permission: 'viewRegistrationRequests',
                },
            },
        ],
    },

    {
        path: '/transfers',
        component: () => import('@/layouts/navigationIndex.vue'),
        children: [
          {
            path: '',
            name: 'transfers',
            component: () => import('@/pages/Procedures/transfers/Edit.vue'),
            meta: {
              pageTitle: 'transferOperations',
              breadcrumb: [{ text: 'procedures', active: true }],
              permission: 'makeTransfers'
            },
          },
        ],
    },
]
