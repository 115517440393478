<template>
  <b-card>
    <g-form @submit="post">
      <b-row class="p-0 mb-1">
        <b-col
          md="5"
        >
          <b-button
            :variant="file.name ? 'success' : 'adn'"
            data-action-type="new"
            class="btn-sm p-75"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon
              icon="UploadIcon"
              class="ml-50"
            />
          </b-button>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-modal
        ref="upload-modal"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('attachFile')"
      >
        <g-form>
          <!-- operations -->
          <b-row>
            <b-col cols="12">
              <div>
                <input
                  type="file"
                  class="excel-upload-input"
                  accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"
                  @change="handleFileUpload($event)"
                >
                <b-alert
                  v-if="file"
                  show
                  fade
                  class="mt-2 text-center"
                  variant="success"
                >
                  <div class="alert-body">
                    <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                class="mr-1"
                variant="relief-primary"
                @click="closeModal"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                {{ $t("save") }}
              </b-button>
              <b-button
                variant="secondary"
                data-action-type="delete"
                :disabled="!file"
                @click="
                  () => {
                    removeAttachment();
                  }
                "
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                {{ $t("cancel") }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-modal>

      <b-row>
        <!-- from month -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.month"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="month"
              field="select"
              name="fromMonth"
              :options="months"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- from year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.year"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="year"
              field="select"
              name="fromYear"
              :options="years"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              :disabled="selectedItem.departmentId || selectedItem.divisionId"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="!selectedItem.employeeId"
              :disabled="selectedItem.divisionId || selectedItem.employeeId"
              label-text="department"
              field="select"
              name="departments"
              :options="departments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.divisionId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="!selectedItem.employeeId"
              :disabled="selectedItem.departmentId || selectedItem.employeeId"
              label-text="division"
              field="select"
              name="divisions"
              :options="divisions"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- </b-row>
      <b-row> -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.depId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="selectedItem.employeeId"
              :disabled="true"
              label-text="department"
              field="select"
              name="departments"
              :options="departments"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.diviId"
              :dir="isRight ? 'rtl' : 'ltr'"
              v-if="selectedItem.employeeId"
              :disabled="true"
              label-text="division"
              field="select"
              name="divisions"
              :options="divisions"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
            v-permission="'addCalculateSalary'"
          >
            {{ $t('calc') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import { months, years } from '@/libs/acl/Lookups';

export default {
  props: ['id'],
  data() {
    return {
      file: '',
      items: [],
      fields: [],
      selectAll: false,
      employees: [],
      departments: [],
      divisions: [],
      months: months,
      years: years,
      selectedItem: {
        month: '',
        year: '',
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'actions',
        },
        {
          key: 'transactions',
        },
      ];
    },
  },
  watch: {
    'selectedItem.employeeId'(employeeId) {
      const emp = this.employees.find((val) => val.id === employeeId);
      this.selectedItem.diviId = emp.divisionId;
      this.selectedItem.depId = emp.departmentId;
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
    this.selectedItem.year = new Date().getFullYear();
    this.selectedItem.month = new Date().getMonth() + 1;
  },
  mounted() {
    this.getEmployee();
    this.getDepartMent();
    this.getDivision();
    this.setYears();
  },
  methods: {
    // set years lookup based on fiscal years
    setYears() {
      if (this.currentYear.year.toString().includes('/')) {
        var one = this.currentYear.year ? this.currentYear.year.split('/')[0] : '';
        this.splityear.push(one);
        var two = this.currentYear.year ? this.currentYear.year.split('/')[1] : '';
        this.splityear.push(two);
        this.years = this.years.filter(year => this.splityear.includes(year.id.toString()))
      } else {
        this.years = this.years.filter(year => this.fiscalYears.some(fiscal => fiscal.year === year.id))
      }
    },
    // get all employees
    getEmployee() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter((x) => !x.isSuspended);
      });
    },
    // get all departments
    getDepartMent() {
      this.get({ url: 'departments' }).then((data) => {
        this.departments = data;
      });
    },
    // get all divison
    getDivision() {
      this.get({ url: 'divisions' }).then((data) => {
        this.divisions = data;
      });
    },
    validateData() {
      // must select month and year
      if (this.selectedItem.month === '' || this.selectedItem.year === '') {
        return false;
      }
      return true;
    },
    validateYear(year, month) {
    var checkDate = new Date(year, month - 1, 1);
    var date = this.getDate(checkDate)
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    post() {
      if (!this.validateData()) {
        this.doneAlert({
          text: this.$t('mustSelectMonthAndYear'),
          type: 'warning',
          timer: 10000,
        });
        return;
      }
      if (!this.validateYear(this.selectedItem.year, this.selectedItem.month)) return;
      if (this.selectedItem.employeeId && this.selectedItem.departmentId && this.selectedItem.divisionId) {
        this.doneAlert({
          text: this.$t('mustSelectEmployeeOrDepartmentOrDivision'),
          type: 'warning',
          timer: 10000,
        });
        return;
      }
      // validate employee hiredate must be lower than salary caculation date
      if (this.selectedItem.employeeId) {
        const employeeById = this.employees.find(employee => employee.id === this.selectedItem.employeeId);
        const employeeHireDate = new Date(employeeById.hireDate);
        const hireDateYearMonth = employeeHireDate.getFullYear() * 100 + (employeeHireDate.getMonth() + 1);
        const selectedYearMonth = this.selectedItem.year * 100 + this.selectedItem.month;
        if (hireDateYearMonth > selectedYearMonth) {
          this.doneAlert({
            text: this.$t('employeeHireDateIsLessThanCaculatedSalaryDate'),
            type: 'warning',
            timer: 10000,
          });
          return;
        }
      }

      // then calculate salary
      this.create({
        url: 'CalculateSaleries',
        data: this.selectedItem,
      })
        .then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.resetData();
        })
        .catch(({ data }) => {
          this.doneAlert({ title: data.errors[0].message, type: 'error' });
        });
    },
    resetData() {
      this.selectedItem.attachmentFileExtension = null;
      this.selectedItem.attachmentBase64Content = null;
      this.selectedItem.attachmentFileName = null;
      this.file = '';
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.imageBase64Content = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t(
            'Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG, .zip suffix files'
          ),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|xlsx|PNG|zip)$/.test(file.name);
    }
  },
};
</script>
