<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col
          cols="12"
        >
          <a
            v-if="selectedItem.attachmentUrl"
            :href="selectedItem.attachmentUrl"
            class="mr-1 btn-sm btn btn-adn p-75"
            @click.prevent="downloadItem(selectedItem)"
          >
            {{ $t("downloadAttachedFile") }}
            <feather-icon
              icon="DownloadIcon"
              class="ml-50"
            />
          </a>

          <b-button
            v-if="selectedItem.isPosted"
            variant="dark"
            data-action-type="new"
            class="btn-sm p-75"
            @click="
              (v) => {
                this.$router.push({
                  name: 'vouchers-edit',
                  params: { id: selectedItem.postedVoucherId },
                });
              }
            "
          >
            {{ `${$t('voucherNum')} ${selectedItem.voucherCode}` }}
          </b-button>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-modal
        ref="upload-modal"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('attachFile')"
      >
        <g-form>
          <!-- operations -->
          <b-row>
            <b-col cols="12">
              <div>
                <input
                  type="file"
                  class="excel-upload-input"
                  accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"
                  @change="handleFileUpload($event)"
                >
                <b-alert
                  v-if="file"
                  show
                  fade
                  class="mt-2 text-center"
                  variant="success"
                >
                  <div class="alert-body">
                    <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                class="mr-1"
                variant="relief-primary"
                @click="closeModal"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                {{ $t("save") }}
              </b-button>
              <b-button
                variant="secondary"
                data-action-type="delete"
                :disabled="!file"
                @click="
                  () => {
                    removeAttachment();
                  }
                "
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                {{ $t("cancel") }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-modal>

      <b-row>
        <b-col md="3">
          <label
            style="font-size: 14px; margin-bottom: 7px"
            for="code"
          >
            {{ $t('employee') }}
          </label>
          <g-field
            id="arabicName"
            :value.sync="selectedItem.arabicName"
            name="arabicName"
          />
        </b-col>
        <b-col md="3">
          <g-field
            :value.sync="selectedItem.basicSalary"
            label-text="basicSalary"
            name="salary"
            :disabled="true"
          />
        </b-col>
        <b-col md="3">
          <g-field
            :value.sync="selectedItem.netSalary"
            label-text="netSalary"
            name="netSalary"
            :disabled="true"
          />
        </b-col>
        <b-col md="3">
          <g-field
            :value.sync="selectedItem.totalSalary"
            label-text="totalSalary"
            name="totalSalary"
            :disabled="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <g-field
            :value.sync="year"
            label-text="year"
            name="salary"
            :disabled="true"
          />
        </b-col>
        <b-col md="6">
          <g-field
            :value.sync="month"
            label-text="month"
            name="month"
            :disabled="true"
          />
        </b-col>
      </b-row>

      <b-row
        class="mt-3"
        align-h="center"
      >
        <b-col cols="5">
          <b-card
            class="border rounded p-0 mb-1"
            :title="$t('benefits')"
          >
            <b-row align-h="left">
              <b-col cols="6">
                <span class="mx-2 h4">
                  <strong>{{ fraction(totalben) }} </strong>
                </span>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-table-simple
                  responsive
                  bordered
                >
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('type') }}</b-th>
                      <b-th>{{ $t('value') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="benefit in selectedItem.benfitAndDeductions"
                      :key="benefit.benefitsDeductionsName"
                    >
                      <b-td v-if="benefit.itemType === 'earning'">
                        {{
                          isRight
                            ? benefit.itemType === 'earning'
                              ? benefit.benefitsDeductionsName
                              : ''
                            : benefit.benefitsDeductionsEnglishName
                        }}
                      </b-td>
                      <b-td v-if="benefit.itemType === 'earning'">
                        {{
                          benefit.itemType === 'earning' ? benefit.total : ''
                        }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card
            class="border rounded p-0 mb-1"
            :title="$t('Deductions')"
          >
            <b-row align-h="left">
              <b-col cols="2">
                <span class="mx-2 h4">
                  <strong>{{ fraction(totalDeductions) }} </strong>
                </span>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-table-simple
                  responsive
                  bordered
                >
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('type') }}</b-th>
                      <b-th>{{ $t('value') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="deduction in selectedItem.benfitAndDeductions"
                      :key="deduction.benefitsDeductionsName"
                    >
                      <b-td v-if="deduction.itemType === 'deduction'">
                        {{
                          isRight
                            ? deduction.itemType === 'deduction'
                              ? deduction.benefitsDeductionsName
                              : ''
                            : deduction.benefitsDeductionsEnglishName
                        }}
                      </b-td>
                      <b-td v-if="deduction.itemType === 'deduction'">
                        {{
                          deduction.itemType === 'deduction'
                            ? deduction.total
                            : ''
                        }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print()"
          >
            <feather-icon
              icon="PrinterIcon"
              size="15"
              class="mr-25"
            />
            {{ $t('print') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      file: '',
      selectedItem: {
        bene: '',
        benfitAndDeductions: [],
      },
      totalben: 0,
      total: 'totalDeductions',
      totalDeductions: 0,
      month: '',
      year: '',
      netSalary: 0
    };
  },
  mounted() {
    // get object in case edit
    if (this.id > 0) {
      this.year = this.$route.query.year;
      this.month = this.$route.query.month;
      this.getSelected();
    }
  },
  methods: {
    // get object from server side
    getSelected() {
      var params = `?employeeId=${this.id}&year=${this.year}&month=${this.month}`;
      this.get({ url: `CalculateSaleries/GetByIds${params}` }).then((data) => {
        this.selectedItem = data;
        // this.netSalary = data.basicSalary;
        data.benfitAndDeductions.forEach((x) => {
          if (x.itemType === 'earning') {
            this.totalben += x.total;
          }
          if (x.itemType === 'deduction') {
            this.totalDeductions += x.total;
          }
        });
        // if (this.totalben > 0) {
        //   this.netSalary += this.totalben;
        // }
        // if (this.totalDeductions > 0) {
        //   this.netSalary = parseFloat(
        //     (this.netSalary -= this.totalDeductions)
        //   ).toFixed(2);
        // }
        // this.netSalary = data.totalSalary - (this.totalben - this.totalDeductions)
      });
    },

    print() {
      const reportName = this.isRight ? 'EmployeePayrolls-ar' : 'EmployeePayrolls-en';
      const printedItem = {
        isForSingleTransaction: true,
        employeeId: this.id,
        fromMonth: this.month,
        fromYear: this.year,
        branchName: this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
      };
      this.printReport(reportName, printedItem);
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
  },
};
</script>
<style>
.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #a074c7;
  border-radius: 5px;
}

.hide-code {
  display: none;
}

.header h4 {
  color: white;
}
</style>
