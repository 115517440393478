<template>
  <b-card>
    <div>
      <b-row>
        <b-col md="2">
          <!-- code  -->
          <g-field
            id="code"
            :value.sync="filter.Code"
            label-text="code"
            name="code"
            @change="() => refreshItems()"
          />
        </b-col>
        <b-col md="2">
          <!-- fromDate  -->
          <label class="dateLabel" for="example-datepicker">{{
            $t("fromDate")
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="filter.FromDate"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            name="fromDate"
            locale="ar"
            @input="() => refreshItems()"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>
        <b-col md="2">
          <!-- ToDate  -->
          <label class="dateLabel" for="example-datepicker2">{{
            $t("toDate")
          }}</label>
          <b-form-datepicker
            id="example-datepicker2"
            v-model="filter.ToDate"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="toDate"
            locale="ar"
            @input="() => refreshItems()"
          ></b-form-datepicker>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <g-field
              :value.sync="filter.IsCanceled"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="showCanceledVouchers"
              field="select"
              name="showCanceledVouchers"
              :options="vouchersOptions"
              label="arabicName"
              @input="() => refreshItems()"
            ></g-field>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <g-field
              :value.sync="filter.IsFromPosting"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="selectVaouchers"
              field="select"
              name="selectVaouchers"
              :options="vouchersOptionsPosting"
              label="arabicName"
              @input="() => refreshItems()"
            ></g-field>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-4 col-12"></div>
        <div
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-12 col-12"
        >
          <b-button
            v-if="currentYear.year && !hasClosedVoucher
              && (profile.permissions.indexOf('enableUnderClosure') > -1 || profile.isAdmin )"
            variant="secondary"
            data-action-type="new"
            class="mr-1"
            @click="
              (v) => {
                this.$router.push({ name: 'closedVoucher-new' });
              }
            "
          >
            {{ $t('newClosedVoucher') }}
          </b-button>
          <b-button
            variant="primary"
            data-action-type="new"
            v-permission="'addVouchers'"
            @click="
              (v) => {
                this.$router.push({ name: 'vouchers-new' });
              }
            "
          >
            {{ $t("new") }}
          </b-button>
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            />
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            />
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="vouchers-table"
      :items="itemsSearchProvider"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      foot-clone
      :totalRows="totalRows"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :createButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
      :tbody-tr-class="
        (item) => {
          if (item && item.isCanceled === true) return 'canseledVouchers';
          if (item && item.isOpening) return 'bg-light-success';
        }
      "
    >
      <template #actions="{ item }" v-if="hideActions === false">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${item.id}-prev-icon`"
            icon="EyeIcon"
            data-action-type="preview"
            class="mx-1 clickable "
            :hidden="true"
            @click="
              () => {
                $router.push({
                  name: 'vouchers-edit',
                  params: { id: item.id },
                });
              }
            "
          />
          <b-tooltip
            :title="$t('preview')"
            placement="bottom"
            :target="`invoice-row-${item.id}-prev-icon`"
          />
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editVouchers'"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            data-action-type="edit"
            @click=" () => { edit(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            data-action-type="download"
            v-b-tooltip.hover.top="
              item.attachmentUrl !== null
                ? $t('hasAttachMents')
                : $t('hasNotAttachMents')
            "
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                if (item.attachmentUrl !== null) {
                  downloadItem(item);
                }
              }
            "
          >
            <feather-icon
              :icon="
                item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon'
              "
              :id="`invoice-row-${item.id}-attach-icon`"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteVouchers'"
            variant="flat-danger"
            data-action-type="delete"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from "@/pages/Shared/Table.vue";
import saveAs from "file-saver";
import reportMixin from "@/mixin/reportMixin";
import { vouchersOptions, vouchersOptionsPosting } from "@/libs/acl/Lookups";

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      check: require("@/assets/images/icons/check.png"),
      xcheck: require("@/assets/images/icons/x.png"),
      searchQuery: "",
      currentPage: 1,
      perPage: 25,
      vouchersOptions: vouchersOptions,
      vouchersOptionsPosting: vouchersOptionsPosting,
      filter: {
        code: "",
        FromDate: "",
        ToDate: "",
        OrderClause: "",
        IsCanceled: "",
        IsFromPosting: "",
        UserId: null,
      },
      isTableBusy: false,
      totalRows: 0,
      stores: [],
      totalVals: 0,
      pathName: this.$route.name,
      itemsArray: [],
      hideActions: false,
      hasClosedVoucher: false
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "code",
          field: "code",
          label: this.$t("transactionNum"),
          sortable: true,
        },
        {
          key: "voucherDate",
          field: "voucherDate",
          label: this.$t("date"),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.voucherDate);
          },
        },
        {
          key: "debentureValue",
          field: "debentureValue",
          label: this.$t("value"),
          sortable: true,
          type: "number",
          footer: () => this.fraction(this.totalVals),
        },
        {
          key: "sourceTransactionCode",
          field: "sourceTransactionCode",
          label: this.$t("reference"),
          sortable: true,
        },
        {
          key: "sourceTransactionType",
          field: "sourceTransactionType",
          label: this.$t("sourceTransactionType"),
          sortable: true,
          formatter: (key, value, item) => {
            const name = this.isRight
              ? item.sourceTransactionType
              : item.sourceTransactionType;
            // if (item.sourceTransactionType === 'returnPurch') return `<span class="badge badge-info">${this.$t(name)}</span>`;
            // if (item.sourceTransactionType === 'purch') return `<span class="badge badge-info">${this.$t(name)}</span>`;
            if (item.sourceTransactionType === "sales")
              return `<span class="badge border-0 bg-gradient-primary p-50">${this.$t(
                name
              )}</span>`;
            // if (item.sourceTransactionType === 'salesOrder') return `<span class="badge badge-primary">${this.$t(name)}</span>`;
            if (item.sourceTransactionType === "returnSales")
              return `<span class="badge border-0 bg-success p-50">${this.$t(
                name
              )}</span>`;
            // if (item.sourceTransactionType === 'customerCollection') return `<span class="badge badge-success">${this.$t(name)}</span>`;
            // if (item.sourceTransactionType === 'supplierPayment') return `<span class="badge badge-warning">${this.$t(name)}</span>`;
            // if (item.sourceTransactionType === 'customerDiscount') return `<span class="badge badge-warning">${this.$t(name)}</span>`;
            // if (item.sourceTransactionType === 'supplierDiscount') return `<span class="badge badge-warning">${this.$t(name)}</span>`;
            if (item.sourceTransactionType === "expenseTransaction")
              return `<span class="badge border-0 bg-warning p-50">${this.$t(
                name
              )}</span>`;
            if (!item.sourceTransactionType && item.isOpening)
              return `<span class="badge border-0 bg-success p-50">${this.$t(
                "openingVoucher"
              )}</span>`;
            if (!item.sourceTransactionType && !item.isUnderClosure)
              return `<span class="badge border-0 bg-dark p-50">${this.$t(
                "manualVoucher"
              )}</span>`;

            if (item.isUnderClosure)
              return `<span class="badge border-0 badge-secondary p-50">${this.$t('closedVoucher')}</span>`;
            return `<span class="badge border-0 bg-danger p-50">${this.$t(name)}</span>`;
          },
        },
        {
          key: "description",
          field: "description",
          label: this.$t("notes"),
          sortable: true,
        },
        { key: "actions" },
      ];
    },
  },
  watch: {
    "filter.FromDate"(newVal) {
      if (newVal > this.filter.ToDate) {
        this.doneAlert({
          title: this.$t("startDateShouldBelessThanEndDate"),
          type: "error",
        });
        return false;
      }
      return true;
    },
    "filter.ToDate"(newVal) {
      if (this.filter.FromDate > newVal) {
        this.doneAlert({
          title: this.$t("startDateShouldBelessThanEndDate"),
          type: "error",
        });
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
    this.handelShowClosedVouchers();
  },
  mounted() {
    this.setDate();
  },
  methods: {
    handelShowClosedVouchers() {
      this.get({ url: 'Vouchers/closing-voucher' }).then((result) => {
        this.hasClosedVoucher = result;
      })
    },
    setDate() {
      this.filter.FromDate = this.fiscalYearStart;
      this.filter.ToDate = this.fiscalYearEnd;
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split(".")[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    hide() {
      this.hideActions = true;
    },
    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function(canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide();
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },
    refreshItems() {
      this.$refs["vouchers-table"].refreshTable();
    },
    itemsSearchProvider(ctx, callback) {
      const { currentPage, perPage, sortBy, sortDesc } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      this.filter.UserId = this.profile.userId;
      if (
        this.profile.permissions.indexOf("showOtherUsersVoucher") !== -1 ||
        this.profile.isAdmin
      ) {
        this.filter.UserId = null;
      }
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `vouchers${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            this.totalVals += item.debentureValue;
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    remove(item) {
      this.confirmAction(
        {
         text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({url: "Vouchers",id: item.id,})
            .then(() => {
              this.doneAlert({ text: this.$t("deletedSuccessfully") });
              if (item.isUnderClosure) this.hasClosedVoucher = false;
              this.refreshItems();
            })
        }
      );
    },
    edit(item) {
      if(this.hasClosedVoucher && !item.isUnderClosure) {
        this.doneAlert({ text: this.$t('thisYearHasUnderClosureAndCannotEdit'), type: 'error', timer: 7000 });
        return;
      } else {
        this.$router.push({
          name: item.isUnderClosure ? 'closedVoucher-edit' : 'vouchers-edit',
          params: { id: item.id },
        });
      }
    },
    print(item) {
      const printedItem = {
        id: item.id,
      };
      this.printReport(this.isRight ? "VouchersReport-ar" : "VouchersReport-en", printedItem);
    },
  },
};
</script>

<style>
.canseledVouchers {
  text-decoration: line-through;
  color: firebrick;
}
</style>
