<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="url"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.imgupload.$el.click()"
              >
                {{ $t("change") }}
              </b-button>
              <b-form-file
                ref="imgupload"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @change="onFileChanged"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
      </b-row>
      <b-row>
        <!-- code  -->
        <b-col md="4">
          <g-field
            id="code"
            label-text="code"
            type="number"
            :value.sync="selectedItem.code"
            name="code"
          />
        </b-col>

        <!-- arabicName  -->
        <b-col md="4">
          <g-field
            id="arabicName"
            ref="arabicName"
            rules="required"
            :value.sync="selectedItem.arabicName"
            name="arabicName"
            label-text="arabicName"
          />
        </b-col>

        <!-- englishName  -->
        <b-col md="4">
          <g-field
            id="englishName"
            ref="englishName"
            :value.sync="selectedItem.englishName"
            name="englishName"
            label-text="englishName"
          />
        </b-col>
      </b-row>

      <b-tabs
        content-class="mt-2"
        pills
        fill
      >
        <b-tab :title="$t('mainData')">
          <b-row>

            <!-- workHours -->
            <b-col md="4">
              <g-field
                id="workHours"
                type="number"
                :value.sync="selectedItem.workHours"
                name="workHours"
                label-text="workHours"
              />
            </b-col>

            <!-- address -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.address"
                name="address"
                label-text="address"
              />
            </b-col>

            <!-- phone1 -->
            <b-col md="4">
              <label
                style="font-size: 14px; margin-bottom: 7px"
                for="phone"
              >
                {{ $t("phone") }} 1
              </label>
              <b-form-input
                v-model="selectedItem.phone1"
                type="number"
              />
            </b-col>

            <!-- phone2 -->
            <b-col md="4">
              <label
                style="font-size: 14px; margin-bottom: 7px"
                for="phone"
              >
                {{ $t("phone") }} 2
              </label>
              <b-form-input
                v-model="selectedItem.phone2"
                type="number"
              />
            </b-col>

            <!-- fax -->
            <b-col md="4">
              <g-field
                :value.sync="selectedItem.fax"
                name="fax"
                label-text="fax"
              />
            </b-col>
          </b-row>

          <!-- notes -->
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="customer"
                >
                  {{ $t("notes") }}
                </label>
                <b-form-textarea
                  id="textarea"
                  v-model="selectedItem.notes"
                  label="Notes"
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab :title="$t('branchSettings')">
          <b-row class="mb-2">
            <!-- decimalDigits  -->
            <b-col md="3">
              <g-field
                field="number"
                rules="min_value:0|max_value:5"
                :value.sync="selectedItem.decimalDigits"
                type="number"
                label-text="decimalDigits"
              />
            </b-col>

            <!-- branchStores  -->
            <b-col v-if="storeEn" md="3" >
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.stores"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="branchStores"
                  field="select"
                  name="branchStores"
                  multiple
                  :clearable="false"
                  :options="allStores"
                  label="arabicName"
                  @option:deselected="(v) => { removeCheck(v) }"
                />
              </b-form-group>
            </b-col>

            <!-- defaultStore  -->
            <b-col md="3" v-if="storeEn">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.defaultStoreId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="defaultStore"
                  field="select"
                  name="defaultStore"
                  :options="selectedItem.stores"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- stocktakingMethod -->
            <b-col v-if="storeEn" md="3">
              <g-field
                :value.sync="selectedItem.stocktakingMethod"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="stocktakingMethod"
                field="select"
                name="stocktakingMethod"
                :options="stocktakingMethods"
                label="arabicName"
              />
            </b-col>
            <!-- costPricePolicy  -->
             <b-col  v-if="storeEn" md="3">
                <g-field
                  :value.sync="selectedItem.costPricePolicy"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="costPricePolicy"
                  field="select"
                  name="costPricePolicy"
                  :options="costPricesPolicy"
                  label="arabicName"
                  rules="required"
                />
            </b-col>
          </b-row>

          <b-row>
            <!-- setDefaultDate  -->
            <b-col md="3">
              <b-form-group :label="$t('setDefaultDate')">
                <b-form-checkbox
                  v-model="selectedItem.setDefaultDate"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>

            <!-- applyTaxOnVoucher  -->
            <b-col md="3">
              <b-form-group :label="$t('applyTaxOnVoucher')">
                <b-form-checkbox
                  v-model="selectedItem.applyTaxOnVoucher"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>

            <!-- activateTaxCollection  -->
            <b-col md="3">
              <b-form-group :label="$t('activateTaxCollection')">
                <b-form-checkbox
                  v-model="selectedItem.isTaxCollectionActivated"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>
            <!-- activateSMSAutoSend  -->
            <b-col md="3">
              <b-form-group :label="$t('activateSMSAutoSend')">
                <b-form-checkbox
                  v-model="selectedItem.isSMSAutoSendActivated"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>
            <!-- isDistributionCostCentersActivated  -->
            <b-col md="3">
              <b-form-group :label="$t('activateDistributionCostCenters')">
                <b-form-checkbox
                  v-model="selectedItem.isDistributionCostCentersActivated">
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('enablePaymentMachen')">
                <b-form-checkbox
                  v-model="selectedItem.enablePaymentMachen"
                  class="custom-control-primary"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="$t('activateWelcomeMessageWhenAddStudent')">
                <b-form-checkbox
                  v-model="selectedItem.isWelcomeMessageActivated"
                  class="custom-control-primary"
                />
              </b-form-group>
              <div v-if="selectedItem.isWelcomeMessageActivated">
                <b-form-textarea
                  id="textarea"
                  v-model="selectedItem.welcomeMessage"
                  rows="3"
                  :placeholder="$t('welcomeMessageContent')"
                />
                <span
                  v-if="!selectedItem.welcomeMessage || !selectedItem.welcomeMessage.trim()"
                  style="color: #ea5455; font-size: 0.857rem;"
                > {{ $t('welcomeMessageRequired') }} </span>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab :title="$t('accountsConnections ')">
          <b-row>

            <!-- registrationIncomeAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.registrationIncomeAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="registrationIncomeAccount"
                  field="select"
                  name="registrationIncomeAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- studentDiscountAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.studentDiscountAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="studentDiscountAccount"
                  field="select"
                  name="studentDiscountAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- taxAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.taxAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="taxAccount"
                  field="select"
                  name="taxAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- custodyRecipientAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.custodyRecipientAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="custodyRecipientAccount"
                  field="select"
                  name="custodyRecipientAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- expensesAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.expensesAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="expensesAccount"
                  field="select"
                  name="expensesAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- incomesAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.incomesAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="incomesAccount"
                  field="select"
                  name="incomesAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- boxAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.boxAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="boxAccount"
                  field="select"
                  name="boxAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- banksAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.banksAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="banksAccount"
                  field="select"
                  name="banksAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- studentsAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.studentsAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="studentsAccount"
                  field="select"
                  name="studentsAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- returnRegistrationIncomeAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.returnRegistrationIncomeAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="returnRegistrationIncomeAccount"
                  field="select"
                  name="returnRegistrationIncomeAccount"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- advanceRevenueAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.advanceRevenueAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="advanceRevenue"
                  field="select"
                  name="advanceRevenue"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- salaryAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.salaryAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="salaries"
                  field="select"
                  name="salaries"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.salaryOwedAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="salariesOwed"
                  field="select"
                  name="salariesOwed"
                  :options="Accounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-tab>

        <b-tab :title="$t('accountsMainConnections')">
          <b-row>

            <!-- studentsMainAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.studentsMainAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="studentsMainAccount"
                  field="select"
                  name="studentsMainAccount"
                  :options="parentAccounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- employeesMainAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.employeesMainAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="employeesMainAccount"
                  field="select"
                  name="employeesMainAccount"
                  :options="parentAccounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

            <!-- expensesMainAccountId -->
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.expensesMainAccountId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="expensesMainAccount"
                  field="select"
                  name="expensesMainAccount"
                  :options="parentAccounts"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-tab>
      </b-tabs>

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ selectedItem.id > 0 ? $t("edit") : $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { stocktakingMethodTypes, costPricesPolicy } from '@/libs/acl/Lookups'

export default {
  props: ['id'],
  data() {
    return {
      selectedItem: {
        englishName: '',
        arabicName: '',
        stocktakingMethod: 'periodic',
        stores: [],
        costPricePolicy: 'lastPrice'
      },
      validateBranch: {
        // isCostCenterFound: false,
        // isItemTransactionFound: false,
        // isSalesFound: false,
        // isVoucherFound: false,
        isPurchFound: false
      },
      Policies: [],
      Accounts: [],
      parentAccounts: [],
      oldData: [],
      allStores: [],
      url: '',
      stocktakingMethods: stocktakingMethodTypes,
      costPricesPolicy: costPricesPolicy,
      storeEn: false,
    };
  },
  beforeMount() {
    if (this.id > 0) {
      this.getValidateBranch();
    } else {
      this.getStores();
    }
  },
  mounted() {
    if (localStorage.storesEnabled === 'true') { this.storeEn = true } else { this.storeEn = false }
    this.selectedItem.costPricePolicy = 'lastPrice';
    this.url = 'default_image.jpg';
    this.getSubAndParentAccounts();
    if (this.id) {
      this.getData();
    } else {
      this.selectedItem.setDefaultDate = true;
    }
  },
  methods: {
    getData() {
      this.get({ url: 'Stores' }).then((data) => {
        this.allStores = data;
      }).then(() => {
        this.get({ url: 'branches', id: this.id }).then((data) => {
          if (data.stores) {
            data.stores.forEach((item) => {
              const storesUpdate = this.allStores.find((val) => val.id === item.storeId) || { arabicName: '', englishName: '' };
              item.id = item.storeId;
              item.arabicName = storesUpdate.arabicName;
              item.englishName = storesUpdate.englishName;
            });
          }
          this.selectedItem = data;
          if (!this.selectedItem.costPricePolicy) this.selectedItem.costPricePolicy = 'lastPrice';
          this.oldData = this.selectedItem.stores;
          this.url = this.selectedItem.logoUrl
            ? `${this.domain}${this.selectedItem.logoUrl}`
            : 'default_image.jpg';
        })
      })
    },
    getStores() {
      this.get({ url: 'Stores' }).then((data) => {
        this.allStores = data;
      });
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.base64Image = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getSubAndParentAccounts() {
      this.get({ url: 'accounts' }).then((data) => {
        this.Accounts = data.filter((acc) => acc.type === 'sub');
        this.parentAccounts = data.filter((acc) => acc.type === 'main');
      });
    },
    getPolicies() {
      this.get({ url: 'Policies' }).then((data) => {
        this.Policies = data;
      });
    },

    async backToList() {
      await new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
        this.$router.push({ name: 'branches-list' });
        window.location.reload();
      });
    },
    validatedBeforeSave() {
      if (this.selectedItem.isWelcomeMessageActivated && !this.selectedItem.welcomeMessage) return false;
      return true;
    },
    save() {
      // validate
      if (!this.validatedBeforeSave()) return;

      // map stores for current branch
      const result = this.selectedItem.stores.map((item) => {
        return {
          branchId: this.selectedItem.id,
          storeId: item.id,
          arabicName: item.arabicName,
          englishName: item.englishName,
          id: item.id,
        };
      });
      this.selectedItem.stores = result;

      if (!this.selectedItem.isWelcomeMessageActivated) {
        this.selectedItem.welcomeMessage = null;
      }

      // then save
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Branches',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.backToList();
        });
      } else {
        this.create({ url: 'Branches', data: this.selectedItem }).then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.backToList();
        });
      }
    },
    removeCheck(v) {
      if (this.oldData.includes(v) && this.validateBranch.isPurchFound) { // (this.validateBranch.isSalesFound || this.validateBranch.isPurchFound)
        this.doneAlert({ text: this.$t('notAllowToRemoveThisStore'), type: 'error' });
        this.selectedItem.stores.push(v);
        return false;
      }
      return true;
    },
    getValidateBranch() {
      this.create({ url: `Branches/validate/branch-isused/${this.id}` }).then((data) => {
        this.validateBranch = data;
      });
    },
  },
};
</script>

<style lang="scss">
  .validateBranch {
     svg {
        display: none !important;
      }
    }
</style>
